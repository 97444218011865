// import html2canvas from "html2canvas";
// import jsPDF from "jspdf";
import PrintIcon from "../../../assets/images/print.png";
import styled from "styled-components/macro";
// import { CompareHeader } from "../header";
import {
  useGetComparePdfMutation,
  useGetCompareProductsQuery,
} from "../../rider/services/rider";
import { useDispatch, useSelector } from "react-redux";
import { downloadCompare } from "../../Landing/serviceApi";
import { clearDownloading, initDownloading } from "../Compare.slice";
import ButtonLoader from "../../../components/Loaders/ButtonLoader/ButtonLoader";
import * as mq from "../../../styles/mediaQueries";

export const download = url => {
  if (url) {
    const a = document.createElement("a");
    a.href = url;
    a.download = url;
    document.body.appendChild(a);
    a.target = "_blank";
    a.click();
    document.body.removeChild(a);
  }
};

const StyledPrintButton = styled.button`
  background: none;
  border: none;
  background-color: var(--primary-color);
  color: #fff;
  font-family: "Inter";
  font-weight: 600;
  border-radius: 6px;
  height: 2.3em;
  width: 100%;
  font-size: 1.1rem;
  ${mq.lg} {
    height: 2.2em;
    font-size: 0.9rem;
  }
`;

const PrintButtonIcon = styled.img`
  max-width: 2em;

  margin-top: -10px;
  margin-left: 85px;
`;

function DownloadCompareButton() {
  const dispatch = useDispatch();
  const [getPdf, { isLoading }] = useGetComparePdfMutation();
  const { showDifference, selectedFeatures } = useSelector(
    state => state.compare,
  );

  function handleClick() {
    let selected = [];
    const key = Object.keys(selectedFeatures);
    key.map(item => {
      selected.push(selectedFeatures[item]);
    });

    selected = selected.flat();
    getPdf({
      selected_features: selected,
      selected_riders: {},
      show_difference: showDifference,
    })
      .then(({ data }) => download(data.data))
      .catch(err => console.error(err));
  }

  return (
    <StyledPrintButton onClick={handleClick} disabled={isLoading}>
      {isLoading ? (
        <ButtonLoader />
      ) : (
        <div>
          Download&nbsp;&nbsp;<i className="far fa-arrow-alt-circle-down"></i>
        </div>
      )}
    </StyledPrintButton>
  );
}

export { DownloadCompareButton };
