import inputSlice from "./Landing/input.slice";
import frontendBootSlice from "./frontendBoot/frontendBoot.slice";
import proposalPageSlice from "./ProposalPage/ProposalPage.slice";
import quotesSlice from "./QuotesPage/Quotes.slice";
import filterSlice from "./Filters/filterSlice";
import cartSlice from "./cart/cart.slice";
import compareSlice from "./ComparePage/Compare.slice";
import compareQuotesSlice from "./ComparePage/ComparePlanPopupSlice.jsx";
import { useGetEnquiriesQuery } from "./rider/services/rider";
import fyntuneLogo from "../assets/images/logo/logo.png";
import { FaTimes } from "react-icons/fa";
import "styled-components/macro";
export {
  inputSlice,
  frontendBootSlice,
  proposalPageSlice,
  quotesSlice,
  filterSlice,
  cartSlice,
  compareQuotesSlice,
  compareSlice,
};

export function FullScreenLoader() {
  // const { isLoading, frontendData } = useSelector(state => state.frontendBoot);
  return (
    <div
      css={`
        height: 100vh;
        width: 100vw;
        display: flex;
        align-items: center;
        justify-content: center;
        position: fixed;
        top: 0;
        left: 0;
      `}
    >
      <img src={fyntuneLogo} alt="loader" width="200" />
    </div>
  );
}

export function LoadEnquiry({ children, loader, ...props }) {
  const { isLoading, isError } = useGetEnquiriesQuery();

  if (isLoading) return <div {...props}>{loader}</div> || <p>Loading...</p>;

  if (isError) return <p>Error</p>;

  return <div {...props}>{children}</div>;
}

export function CloseButton({ css = ``, ...props }) {
  return (
    <button
      css={`
        position: absolute;
        right: 5px;
        top: 0;
        background-color: transparent;
        height: 1.2em;
        width: 1.2em;
        font-size: 1.2rem;
        border-radius: 50%;
        border: 1px solid black;
        display: flex;
        align-items: center;
        justify-content: center;

        ${css};
      `}
      {...props}
    >
      <FaTimes />
    </button>
  );
}
