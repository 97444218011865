import React from "react";
import styled, { css } from "styled-components/macro";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { BsFillShareFill } from "react-icons/bs";
import { useNavigate } from "react-router";
import useUrlQuery from "../../../custom-hooks/useUrlQuery";
import { useState } from "react";
import ShareCompareQuotes from "../share/ShareCompareQuotes";
import * as mq from "./../../../styles/mediaQueries";
const CompareHeader = () => {
  const navigate = useNavigate();
  const enquiryId = useUrlQuery("enquiryId");
  const [showPopup, setShowPopup] = useState(false);

  return (
    <>
      <StyledHeaderWrap>
        <BackButton onClick={() => navigate(`/quote?enquiryId=${enquiryId}`)}>
          <AiOutlineArrowLeft />
        </BackButton>
        <ShareButton
          onClick={() => {
            setShowPopup(true);
          }}
        >
          <BsFillShareFill />
        </ShareButton>
      </StyledHeaderWrap>
      <ShareCompareQuotes showPopup={showPopup} setShowPopup={setShowPopup} />
    </>
  );
};

export default CompareHeader;

const StyledHeaderWrap = styled.header`
  position: sticky;
  top: 30px;
  z-index: 11;
  color: var(--secondary-text);
  font-family: "Inter";
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const BackButton = styled.div`
  cursor: pointer;
  height: 35px;
  width: 35px;
  font-size: 16px;
  color: black;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--tertiary-shade);
  margin-left: -4%;
  ${mq.lg} {
    height: 32px;
    width: 32px;
    font-size: 15px;
    margin-left: -3.2%;
  }
`;

const ShareButton = styled.div`
  cursor: pointer;
  height: 35px;
  width: 35px;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
  border-radius: 50%;
  box-shadow: rgb(235 236 244) 1px 1px 6px 1px;
  font-family: "Inter";
  font-weight: 600;
  background: white;
  margin-right: -4%;
  ${mq.lg} {
    height: 32px;
    width: 32px;
    font-size: 15px;
    margin-right: -3.2%;
  }
`;
