import { useSelector } from "react-redux";
import useGetAmount from "./useGetAmount";
import useGetInWords from "./useGetInWords";

const useGetInvestmentData = ({ quoteData }) => {
  const benefit = quoteData?.maturity_benefit;
  const benefitWord = useGetInWords(benefit);
  const getMoneyAs = useSelector(state => state?.filters?.getMoneyAs);
  const investForValue = useSelector(state => state.filters.investFor) || 10;
  const returnsAfterValue =
    useSelector(state => state.filters.returnsAfter) || 20;
  const incomeForValue = useSelector(state => state.filters.incomeFor) || 0;
  const totalAmount = useGetAmount();
  const totalAmountWords = useGetInWords(totalAmount);
  const returnCalc = (amount, returnAmount, payTerm) => {
    if (amount === "0") return "0";
    let total = parseInt(amount) * parseInt(payTerm);
    let amt = parseInt(parseInt(returnAmount) - parseInt(total));
    if (amt <= 0) return "0";
    amt = parseFloat(amt) / parseFloat(total);
    amt = amt.toFixed(2);
    return amt.toString();
  };
  const returnPercentage = returnCalc(totalAmount, benefit, investForValue);
  let investedAmount = useSelector(state => state.filters.investedAmount);
  let payFreq = useSelector(state => state.filters.payFrequency);
  console.log({ investForValue });
  const findFrequency = payFreq => {
    switch (payFreq) {
      case "0":
        return "Yearly";
      case "1":
        return "Half Yearly";
      case "2":
        return "Quarterly";
      case "3":
        return "Monthly";
      default:
        return "Yearly";
    }
  };
  const multiplier = {
    0: 1,
    1: 2,
    2: 4,
    3: 12,
  };
  const incomeMonth = () => {
    const benefit = quoteData?.maturity_benefit;
    const timeTakes = incomeForValue * parseInt(12);
    const FinalValue = parseInt(parseInt(benefit) / parseInt(timeTakes));
    return FinalValue;
  };

  const incomePerMonth = useGetInWords(incomeMonth());

  return {
    totalAmountWords,
    returnPercentage,
    benefitWord,
    getMoneyAs,
    investForValue,
    incomeForValue,
    incomePerMonth,
    returnsAfterValue: Number(returnsAfterValue),
    investedAmountInWords: useGetInWords(investedAmount),
    frequency: findFrequency(payFreq),
    totalInvestment: useGetInWords(
      investedAmount * investForValue * multiplier[payFreq],
    ),
    isLumpsum: getMoneyAs === "L",
    isBoth: getMoneyAs === "B",
    isIncome: getMoneyAs === "I",
    multiplier: multiplier[payFreq],
    isSinglePay: +investForValue === 1,
  };
};
export default useGetInvestmentData;
