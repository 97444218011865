import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import React, { useState } from "react";
import { styled } from "@material-ui/core";
import useGetFilterOptions from "../../../../../../../custom-hooks/useGetFilterOptions";
import { useSelector } from "react-redux";
import * as mq from "../../../../../../../styles/mediaQueries";
import useSetFilter from "../../../../../../../custom-hooks/useSetFilter";

function InvestedFor({ setShow }) {
  const [investForValue, setInvestForValue] = useState(
    useSelector(state => state.filters.investFor),
  );
  const investForOptions = useGetFilterOptions({ filter: "pay_term" });

  useSetFilter({ filter: "investFor", value: investForValue });
  const [investForCustomOption, setInvestForCustomOption] = useState([
    { code: "1", display_name: "Pay Once" },
    ...investForOptions,
  ]);
  return (
    <>
      <CustomGroup
        color="primary"
        value={investForValue}
        exclusive
        onChange={e => {
          setInvestForValue(e.target.value);
          setShow(false);
        }}
      >
        {investForCustomOption.length > 0 &&
          investForCustomOption.map((item, index) => (
            <CustomButton key={index} value={item.code}>
              {item.display_name}
            </CustomButton>
          ))}

        {/* {investForOptions.length > 0 &&
          investForOptions.map((item, index) => (
            <CustomButton key={index} value={item.code}>
              {item.display_name}
            </CustomButton>
          ))} */}
      </CustomGroup>
    </>
  );
}

const CustomGroup = styled(ToggleButtonGroup)({
  "&.MuiToggleButtonGroup-root": {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
    gap: "5px",
  },
});

const CustomButton = styled(ToggleButton)({
  width: "48%",
  padding: "5px",
  border: "none",
  outline: "none",
  borderRadius: "5px !important",
  fontSize: "12px",
  fontFamily: "Inter",
  fontWeight: "600",
  backgroundColor: "rgba(79, 92, 111, 0.1)",
  textTransform: "capitalize",
  "&.Mui-selected, &.Mui-selected:hover": {
    color: "white",
    backgroundColor: "var(--primary-color)",
  },
  [mq.md]: {
    fontSize: "18px",
    padding: "10px",
  },
  [mq.sm]: {
    fontSize: "12px",
  },
});

export default InvestedFor;
