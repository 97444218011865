import React from "react";
import { useNavigate } from "react-router";
import * as mq from "../../styles/mediaQueries";
import useMediaQuery from "../../custom-hooks/useMediaQuery";
import useUrlQuery from "../../custom-hooks/useUrlQuery";
import { BiArrowBack } from "react-icons/bi";
import styled from "styled-components/macro";
import { useGetCartQuery } from "../rider/services/rider";
import ButtonLoader from "../../components/Loaders/ButtonLoader/ButtonLoader";
import { useRedirection } from "../../utils/helper";

function RiderHeaderNew() {
  const navigate = useNavigate();
  const matches = useMediaQuery(`${mq.smForHook}`);
  const enquiryId = useUrlQuery("enquiryId");
  return (
    <div>
      <div
        css={`
          width: 1200px;
          display: flex;
          margin: 20px auto;
          font-family: "Inter";
          padding: 0 15px;
          align-items: center;
          justify-content: center;
          position: relative;
          ${mq.sm} {
            max-width: 100%;
            margin-top: 2em;
            margin-bottom: 2.5em;
          }
          ${mq.lg} {
            max-width: 100%;
          }
          ${mq.xs} {
            justify-content: space-between;
          }
        `}
      >
        <div
          css={`
            font-size: 14px;
            cursor: pointer;
            display: flex;
            align-items: center;
            gap: 8px;
            position: absolute;

            left: 0;
            ${mq.sm} {
              left: 15px;
            }
          `}
          onClick={() => navigate(`/quote?enquiryId=${enquiryId}`)}
        >
          {!matches ? (
            <>
              <BiArrowBack /> Go Back to quotes
            </>
          ) : (
            <>
              <BiArrowBack /> Back to quotes
            </>
          )}
        </div>
        <div
          css={`
            margin: auto;
            flex: 1;
            text-align: center;
            font-size: 18px;
            font-weight: 600;

            ${mq.xs} {
              display: none;
              font-size: 14px;
              font-weight: 600;
            }
            ${mq.sm} {
              display: none;
            }
          `}
        >
          Great choice, customize your plan with Add-ons
        </div>
        <div
          css={`
            position: absolute;
            right: 15px;
          `}
        >
          <SkipButton />
        </div>
      </div>
    </div>
  );
}

export default RiderHeaderNew;

function SkipButton() {
  const { isCartLoading, handleClick, isLoading, cartData } = useRedirection();
  // const { indiaFirstRiderVaildationError } = useSelector(state => state.quotes);
  if (isCartLoading || !cartData) return <p>Loading...</p>;

  const { investment_riders } = cartData;

  const ridersAddedLength = investment_riders.length;

  return (
    <StyledSkipButton
      onClick={handleClick}
      //   disabled={indiaFirstRiderVaildationError}
    >
      {isLoading ? (
        <ButtonLoader colored />
      ) : ridersAddedLength === 0 ? (
        `Skip `
      ) : (
        `Continue with ${ridersAddedLength} ${
          ridersAddedLength === 1 ? "rider" : "riders"
        }`
      )}
    </StyledSkipButton>
  );
}

const StyledSkipButton = styled.button`
  padding: 0.76em 3.7em;
  border: ${props =>
    props.disabled ? "none" : "1px solid var(--primary-color)"};
  color: ${props => (props.disabled ? "#fff" : "var(--primary-color)")};
  text-decoration: none;
  border-radius: 0.16em;
  font-size: 0.9rem;
  background: ${props =>
    props.disabled ? "var(--primary-color-light)" : "none"};
  ${mq.sm} {
    font-size: 0.7rem;
    padding: 0.66em 2.7em;
  }
`;
