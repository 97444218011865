import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

function useGetAmount() {
  function editAmount(val) {
    let temp = parseInt(val);

    if (payFreq == "0") {
      return val;
    } else if (investForValu == "1") {
      return val;
    } else if (payFreq == "1") {
      temp = temp * 2;
    } else if (payFreq == "2") {
      temp = temp * 4;
    } else if (payFreq == "3") {
      temp = temp * 12;
    }
    if (temp === null || temp === undefined || temp === 0) {
      return "0";
    }
    return temp.toString();
  }
  let investedAmount = useSelector(state => state.filters.investedAmount);
  let payFreq = useSelector(state => state.filters.payFrequency);
  let investForValu = useSelector(state => state.filters.investFor);
  //   let amount = editAmount(investedAmount) || "0";
  const [amount, setAmount] = useState(editAmount(investedAmount) || "0");
  useEffect(() => {
    setAmount(editAmount(investedAmount));
  }, [investedAmount, payFreq, investForValu]);

  return amount;
}

export default useGetAmount;
