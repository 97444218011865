import React from "react";
import { useSelector } from "react-redux";
import * as mq from "../../styles/mediaQueries";
import styled from "styled-components";

const FeatureSectionContext = React.createContext();
FeatureSectionContext.displayName = "FeatureSectionContext";

export function useFeatureSection() {
  const context = React.useContext(FeatureSectionContext);

  if (!context)
    throw new Error(
      "`useFeature must be used within FeatureSectionContextProvider"
    );

  return context;
}
function FeatureSection({ heading = "", children, ...props }) {
  const { childCount } = useSelector((state) => state.compare);
  return (
    <FeatureSectionContext.Provider value={{ heading }}>
      <StyledSectionWrap {...props}>
        <GradientHeading>{heading}</GradientHeading>
        <SectionContent>{children}</SectionContent>
      </StyledSectionWrap>
    </FeatureSectionContext.Provider>
  );
}

export { FeatureSection };

const StyledSectionWrap = styled.section`
  display: ${(props) => props.noChild && "none"};
  margin-top: 2em;
  padding: 0 1.6em 1.6em 0;
  background: #fff;
  border-radius: 10px 0px 0px 0px;
  box-shadow: rgb(0 0 0 / 10%) 0px 5px 15px 0px;
  ${mq.md} {
    padding: 0;
    margin-top: 1em;
  }
`;

const GradientHeading = styled.h2`
  background: var(--primary-color-light);
  padding: 0.76em 0.93em;
  color: var(--primary-color);
  text-transform: uppercase;
  font-family: "Inter";
  font-weight: 600;
  font-size: 1.06rem;
  border-radius: 9px 0px;
  width: max-content;
  ${mq.lg} {
    font-size: 0.85rem;
  }
  ${mq.md} {
    width: 80%;
    font-size: 0.7rem;
  }
`;

const SectionContent = styled.div`
  margin: 1em;
`;
