import { combineReducers } from "redux";
import {
  frontendBootSlice,
  inputSlice,
  proposalPageSlice,
  quotesSlice,
  filterSlice,
  compareQuotesSlice,
  cartSlice,
  compareSlice,
} from "../pages/index";

import { riderApi } from "../pages/rider/services/rider";
export default combineReducers({
  frontendBoot: frontendBootSlice,
  inputForm: inputSlice,
  quotes: quotesSlice,
  proposalForm: proposalPageSlice,
  compare: compareSlice,
  cart: cartSlice,
  compareQuotes: compareQuotesSlice,
  [riderApi.reducerPath]: riderApi.reducer,
  filters: filterSlice,
});
