import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setGetMoneyAs,
  setIncomeFor,
  setInvestedAmount,
  setPayFrequency,
  setReturnsAfter,
  setInvestFor,
  changeFilter,
} from "../pages/Filters/filterSlice";

const useSetFilter = ({ filter, value }) => {
  const dispatch = useDispatch();
  const {
    getMoneyAs,
    investedAmount,
    investFor,
    returnsAfter,
    incomeFor,
    payFrequency,
  } = useSelector(state => state.filters);

  useEffect(() => {
    if (filter === "getMoneyAs") {
      if (value === getMoneyAs) {
        return;
      }
      dispatch(setGetMoneyAs(value));
      dispatch(changeFilter({ get_money_as: value }), true);
    } else if (filter === "returnsAfter") {
      if (value?.toString() === returnsAfter?.toString()) {
        return;
      }
      dispatch(setReturnsAfter(value));
      dispatch(changeFilter({ policy_term: value }), true);
    } else if (filter === "investFor") {
      if (value?.toString() === investFor?.toString()) {
        return;
      }
      dispatch(setInvestFor(value));
      dispatch(changeFilter({ pay_term: value?.toString() }));
    } else if (filter === "incomeFor") {
      if (value?.toString() === incomeFor?.toString()) {
        return;
      }
      dispatch(setIncomeFor(value));
      dispatch(changeFilter({ income_for: value?.toString() }));
    } else if (filter === "payFrequency") {
      if (value === payFrequency) {
        return;
      }
      dispatch(setPayFrequency(value));
      dispatch(changeFilter({ pay_frequency: value }), true);
    } else if (filter === "investedAmount") {
      if (value === investedAmount) {
        return;
      }
      dispatch(setInvestedAmount(value));
      dispatch(changeFilter({ investment_amount: parseInt(value) }));
    }
  }, [value]);
};

export default useSetFilter;
