import React, { useEffect } from 'react'
import { useSelector } from 'react-redux';

function useGetPayFreqWord() {
    let payFreq = useSelector((state) => state.filters.payFrequency);
    function update() {
        if (payFreq === '0') return "yearly";
        if (payFreq === '1') return "half yearly";
        if (payFreq === '2') return "quaterly";
        if (payFreq === '3') return "monthly";

        return "yearly";
    }
    let temp = update();

    useEffect(() => {
        temp = update();
    }, [payFreq]);

    return temp;
}

export default useGetPayFreqWord