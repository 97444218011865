import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
//
import {
  generateBI,
  getProposalApi,
  proposalUpdate,
  saveProposalApi,
  submitProposalApi,
} from "./serviceApi";
const proposal = createSlice({
  name: "proposalForm",

  initialState: {
    proposalData: {},
    formList: [],
    activeFormIndex: 0,
    position: 0,
    isBi: false,
    isDocumentUploadLoading: false,
    distributor_summary: null,
    redirection_triggered: false,
  },
  reducers: {
    setProposalData: (state, { payload }) => {
      state.proposalData = { ...state.proposalData, ...payload };
    },
    clearData: (state, { payload }) => {
      state.proposalData = {};
    },
    setPosition: (state, { payload }) => {
      state.position = payload;
    },
    setIsBi: (state, { payload }) => {
      state.isBi = payload;
    },
    setFormNamesList: (state, { payload }) => {
      state.formList = payload;
    },
    setActiveFormIndex: (state, { payload }) => {
      state.activeFormIndex = payload;
    },
    clearProposalSubmitLoading: state => {
      state.isProposalSubmitLoading = false;
    },
    clearProposalLoading: state => {
      state.isProposalLoading = false;
    },
    setRedirectData: (state, { payload }) => {
      state.redirectData = { ...state.redirectData, ...payload };
    },
    initProposalSubmitLoading: state => {
      state.isProposalSubmitLoading = true;
    },
    initDocumentUploadLoading: state => {
      state.isDocumentUploadLoading = true;
    },
    clearDocumentUploadLoading: state => {
      state.isDocumentUploadLoading = false;
    },
    setDistributorSummary: (state, action) => {
      state.distributor_summary = action.payload;
    },
    //used in rider page
    setRedirectionTriggered: (state, action) => {
      state.redirection_triggered = action.payload;
    },
  },
});
export const {
  setRedirectData,
  setFormNamesList,
  setActiveFormIndex,
  setPosition,
  setProposalData,
  setIsBi,
  clearData,
  initProposalSubmitLoading,
  clearProposalLoading,
  clearProposalSubmitLoading,
  initDocumentUploadLoading,
  clearDocumentUploadLoading,
  setDistributorSummary,
  setRedirectionTriggered,
} = proposal.actions;
export const getProposalData = () => {
  return async (dispatch, getState) => {
    try {
      const { data } = await getProposalApi();
      dispatch(setProposalData(data.data));
      if (data.data instanceof Array) {
        dispatch(clearData());
      }
      dispatch(setPosition(Object.keys(data.data).length));
      dispatch(clearProposalLoading());
    } catch (err) {
      console.error(err);
      // toast.error("Something went wrong", {
      //   hideProgressBar: true,
      //   toastId: "failed1",
      //   autoClose: 3000,
      //   position: "bottom-center",
      //   theme: "colored",
      // });
      toast.error(`${err.response.data.message}`, {
        hideProgressBar: true,
        toastId: "failed1",
        autoClose: 3000,
        position: "top-center",
        theme: "colored",
      });
    }
  };
};

export const clearProposalData = () => {
  return async (dispatch, getState) => {
    try {
      const { data } = await getProposalApi();
      dispatch(clearData(data));
      dispatch(setPosition(0));
    } catch (err) {
      console.error(err);
      toast.error(`${err.response.data.message}`, {
        hideProgressBar: true,
        toastId: "failed1",
        autoClose: 3000,
        position: "top-center",
        theme: "colored",
      });
    }
  };
};

export const saveProposalData = (req, next = () => {}, isBI, company_alias) => {
  return async (dispatch, getState) => {
    try {
      dispatch(setProposalData(req));
      // eslint-disable-next-line no-unused-vars
      const { data } = await saveProposalApi(req);
      let url = "";
      if (isBI) {
        const {
          data: { data },
        } = await generateBI({ company_alias });
        dispatch(setIsBi(false));
        url = data[0].bi_url;
      }

      next();
      return url;
    } catch (err) {
      console.error(err, "twst101");
      toast.error(`${err.response.data.message}`, {
        hideProgressBar: true,
        toastId: "failed1",
        autoClose: 3000,
        position: "top-center",
        theme: "colored",
      });
    }
  };
};
export const submitProposalData = (req, next = () => {}) => {
  return async dispatch => {
    try {
      const { data } = await submitProposalApi(req);
      dispatch(setRedirectData(data.data[0]));
      next(data.data[0]);
      dispatch(clearProposalSubmitLoading());
    } catch (err) {
      console.error(err, "twst102");
      dispatch(clearProposalSubmitLoading());
      toast.error(`${err.response.data.message}`, {
        hideProgressBar: true,
        toastId: "failed1",
        autoClose: 3000,
        position: "top-center",
        theme: "colored",
      });
    }
  };
};
export const savePosition = data => {
  return async dispatch => {
    try {
      dispatch(setPosition(data));
    } catch (err) {
      console.error(err);
    }
  };
};
export const updateProposal = data => {
  return async () => {
    try {
      const xyz = await proposalUpdate(data);
      const { proposal } = xyz?.data;
      const { proposal_pdf } = proposal?.extras;
      return proposal_pdf;
    } catch (error) {
      console.error(error);
      toast.error(`${error.response.data.message}`, {
        hideProgressBar: true,
        toastId: "failed1",
        autoClose: 3000,
        position: "top-center",
        theme: "colored",
      });
    }
  };
};
export const proposal_status = {
  PROPOSAL_SUBMITTED: "proposal_submitted",
  POLICY_ISSUED: "policy_issued",
  POLICY_REJECTED: "policy_rejected",
  POLICY_CANCELLED: "policy_cancelled",
  DOCUMENT_PENDING: "document_pending",
  DOCUMENT_SUBMITTED: "document_submitted",
  MEDICAL_PENDING: "medical_pending",
  MEDICAL_SUBMITTED: "medical_submitted",
  OTP_PENDING: "otp_pending",
  OTP_VERIFIED: "otp_verified",
};
export default proposal.reducer;
