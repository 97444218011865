import React, { useState } from "react";
import styled from "styled-components/macro";
import * as mq from "../../../../styles/mediaQueries";
import { BsDash, BsPlus } from "react-icons/bs";
import { AdditionalText } from "./AdditionalFeatures";
import { ModalBodyInner } from "./GetDetails";
import {
  useGetRiderPremiumMutation,
  useGetRidersQuery,
} from "../../../rider/services/rider";
import { Box, Typography } from "@mui/material";
import { sendRiderFormat } from "../../../../utils/helper";
import { useDispatch, useSelector } from "react-redux";
import {
  insertAddedRiders,
  removeAddedRiders,
  removeShortName,
  updateRiderShortName,
} from "../../Quotes.slice";

function AddCoverage({ quoteData }) {
  const { data: riders, isLoading: isRidersLoading } = useGetRidersQuery({
    productId: quoteData?.product?.id,
    investment_insurance_id: quoteData?.investment_insurance_id,
  });

  const { product } = quoteData;
  const [hideRiders, setHideRiders] = useState([]);
  const [recalculateRiders, setRecalculateRiders] = useState([]);

  const addToReCalculateRiders = rider_shortname =>
    setRecalculateRiders([...recalculateRiders, ...rider_shortname]);

  const removeFromReCalculateRiders = recalc_riders =>
    setRecalculateRiders(
      recalculateRiders.filter(
        rider => !recalc_riders.includes(rider.rider_shortname),
      ),
    );

  const addToHideRiders = rider_shortname =>
    setHideRiders([...hideRiders, ...rider_shortname]);
  const removeFromHideRiders = rider_shortname =>
    setHideRiders(hideRiders.filter(rider => rider_shortname === rider));

  console.log("Pro", product.id);

  console.log("Riders", riders);

  if (!riders || !riders.length) {
    return (
      <Box display={"flex"} justifyContent="center">
        <Typography variant="h6">No Riders Available</Typography>
      </Box>
    );
  }
  return (
    <ModalBodyInner>
      <AddOnWrapper>
        <AddonHead>Customize Your Plan By Selecting Add-Ons</AddonHead>
        {riders?.map(rider => (
          <AddonCard
            key={rider.rider_id}
            title={rider.rider_name}
            desc={rider.rider_description}
            premium={rider.net_premium}
            rider={rider}
            hideRiders={hideRiders}
            productId={product.id}
            addToHideRiders={addToHideRiders}
            removeFromHideRiders={removeFromHideRiders}
            recalculateRiders={recalculateRiders}
            addToReCalculateRiders={addToReCalculateRiders}
            removeFromReCalculateRiders={removeFromReCalculateRiders}
          />
        ))}
      </AddOnWrapper>
    </ModalBodyInner>
  );
}
const AddonCard = ({
  title,
  premium,
  desc,
  rider,
  productId,
  addToHideRiders,
  removeFromHideRiders,
  recalculateRiders,
  addToReCalculateRiders,
  removeFromReCalculateRiders,
  hideRiders,
}) => {
  const dispatch = useDispatch();
  const [finalRider, setFinalRider] = useState(rider);
  const { rider_shortname } = finalRider;

  React.useEffect(() => {
    setFinalRider(rider);
  }, [rider]);

  const riders = sendRiderFormat({
    policy_term: rider.default_policy_term,
    sum_assured: rider.default_sum_assured,
    premiumPayingTerm: rider.default_ppt,
    ...rider,
  });

  const { addedRiders, addedShortNames, shortNameToRemove, isDiabetic } =
    useSelector(state => state.quotes);

  const isRiderAdded = rider => {
    if (!addedRiders[productId]) {
      return false;
    } else {
      const isAdded = !!addedRiders[productId].find(
        item => item.id === rider.id,
      );
      return isAdded;
    }
  };
  const add = rider => {
    if (!addedShortNames.data.includes(rider_shortname))
      dispatch(
        updateRiderShortName({
          data: [...addedShortNames.data, rider_shortname],
          productId: productId,
        }),
      );
    dispatch(insertAddedRiders({ productId, rider }));
    if (rider.hide_riders) {
      addToHideRiders(rider.hide_riders);
    }
    if (rider.recalc_rider) {
      addToReCalculateRiders(rider.recalc_rider);
    }
  };

  const remove = rider => {
    if (rider.recalc_rider) {
      removeFromReCalculateRiders(rider.recalc_rider);
    }
    if (shortNameToRemove === rider_shortname) {
      dispatch(removeShortName(rider_shortname));
    }
    if (rider.hide_riders) {
      removeFromHideRiders(rider.hide_riders);
    }
    dispatch(
      removeAddedRiders({
        id: rider.id,
        productId,
      }),
    );
    return;
  };
  const isAdded = isRiderAdded(riders);

  const onHandleAddOrRemove = rider => {
    if (isRiderAdded(rider)) {
      console.log("RiderOut", rider);
      remove(rider);
    } else add(rider);
    console.log("RiderIn", rider);
  };
  const isHidden = hideRiders.includes(rider_shortname);
  if (isHidden) return <></>;

  console.log("Added Riders", addedRiders);

  return (
    <AddOnInd>
      <AddOnIndHead>
        <Heading>{title}</Heading>
        <AddButton onClick={() => onHandleAddOrRemove(rider)}>
          ₹ {premium}
          <AddIcon>
            <BsPlus />

            {/* {addedShortNames.data.includes(rider_shortname) ? (
              <BsDash />
            ) : (
              <BsPlus />
            )} */}
          </AddIcon>
        </AddButton>
      </AddOnIndHead>
      <AdditionalText>{desc}</AdditionalText>
    </AddOnInd>
  );
};
const AddOnWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  ${mq.md} {
    width: 100%;
    font-family: "Inter";
    justify-content: center;
    gap: 6px;
    align-items: center;
  }
`;
const AddonHead = styled.div`
  font-family: "Inter";
  font-size: 18px;
  width: 100%;
  margin-bottom: 10px;
  font-weight: 600;
  ${mq.lg} {
    font-size: 16px;
  }
  ${mq.md} {
    margin-top: 10px;
    font-size: 15px;
  }
  ${mq.xs} {
    font-size: 13px;
  }
`;
const AddOnInd = styled.div`
  width: calc(50% - 10px);
  min-height: 126px;
  height: fit-content;
  border-radius: 8px;
  border: 1px solid transparent;
  box-shadow: rgb(0 0 0 / 10%) 0px 3px 8px 0px;
  background-color: rgb(255, 255, 255);
  padding: 15px 15px 30px 15px;

  ${mq.lg} {
    padding: 10px 10px 20px 10px;
  }

  ${mq.md} {
    width: 100%;
    min-height: auto;
    height: fit-content;
    padding: 10px 10px 20px 10px;
  }
  ${mq.xs} {
    padding: 10px 10px 15px 10px;
  }
`;

const AddOnIndHead = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  height: fit-content;
  justify-content: space-between;
  margin-bottom: 15px;
  ${mq.lg} {
    margin-bottom: 10px;
  }
`;

const Heading = styled.div`
  width: 70%;
  height: fit-content;
  font-family: "Inter";
  font-weight: 600;
  font-size: 16px;
  ${mq.lg} {
    font-size: 14px;
  }

  ${mq.md} {
    font-size: 12px;
  }
`;

const AddButton = styled.button`
  font-family: "Inter";
  font-weight: 600;
  width: 25%;
  height: 40px;
  outline: none;
  padding: 0px 10px;
  font-size: 14px;
  border: 1px solid var(--primary-color);
  background-color: white;
  color: var(--primary-color) !important;
  display: flex;
  justify-content: space-around;
  align-items: center;
  ${mq.lg} {
    font-size: 12px;
  }
  ${mq.md} {
    width: 40%;
    font-size: 12px;
  }
  ${mq.xs} {
    width: 35%;
    font-size: 11px;
    height: 35px;
  }
`;
const AddIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  font-size: 14px !important;
  color: var(--primary-color) !important;
  height: 25px;
  border-radius: 50%;
  background-color: rgb(230, 243, 255);
`;

export default AddCoverage;
