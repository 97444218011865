import React, { useEffect } from "react";
import * as mq from "../../styles/mediaQueries";
import styled from "styled-components/macro";
import {
  useGetCartQuery,
  useGetDifferentQuoteQuery,
  useGetQuoteQuery,
} from "./services/rider";
import { useFrontendData } from "../frontendBoot/useFrontendData";
import ButtonLoader from "../../components/Loaders/ButtonLoader/ButtonLoader";
import { useDispatch, useSelector } from "react-redux";
// import { setSelectedQuote } from "../QuotesPage/Quotes.slice";
import { setSelectedQuote } from "../QuotesPage/Quotes.slice";

function PlanCard({
  handleContinueClick,
  cartData,
  company_alias,
  product_id,
  isRedirectionLoading,
  hideBuyButton,
  doneButton,
  handleClose,
}) {
  const filterData = useSelector(state => state.filters);
  const { selectedQuote } = useSelector(state => state.quotes);
  const { getMoneyAs, payFrequency } = filterData;

  const { getCompanyLogo } = useFrontendData();
  const logoSrc = getCompanyLogo(company_alias);
  const dispatch = useDispatch();
  const { newFilters } = useSelector(state => state.inputForm);
  const isDifferent = false;
  const {
    data: quoteApiData,
    isLoading: isQuoteDataLoading,
    isFetching: quoteDataFetching,
  } = useGetQuoteQuery(
    { company_alias, newFilters },
    {
      skip: isDifferent,
    },
  );
  const { data: different_data, isLoading: isDifferentQuoteLoading } =
    useGetDifferentQuoteQuery(
      { company_alias, newFilters },
      {
        skip: !isDifferent,
      },
    );
  const quotes = isDifferent
    ? different_data?.data === undefined
      ? []
      : different_data?.data?.filter(item => item?.product.id === product_id)
    : quoteApiData?.data === undefined
    ? []
    : quoteApiData?.data?.filter(item => item?.product.id === product_id);
  const quote = quotes[0];

  useEffect(() => {
    if (quote && selectedQuote?.product?.id !== quote?.product?.id) {
      dispatch(setSelectedQuote(quote));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quote]);

  const findFrequency = pay => {
    switch (pay) {
      case "0":
        return "Yearly";
      case "1":
        return "Half Yearly";
      case "2":
        return "Quarterly";
      case "3":
        return "Monthly";
      default:
        return "Yearly";
    }
  };

  return (
    <>
      {isQuoteDataLoading || quoteDataFetching || isDifferentQuoteLoading ? (
        <>Loading..</>
      ) : (
        <PlanCardWrapper>
          <PlanCardHeader>
            <div
              css={`
                width: 35%;
                height: 100%;
                overflow: hidden;
              `}
            >
              <img src={logoSrc} alt={"Logo"} />
            </div>
            <PlanHeadData>
              <div
                css={`
                  font-family: Inter;
                  font-weight: 600;
                `}
              >
                {}
              </div>
              <div
                css={`
                  font-family: Inter;
                  font-weight: 300;
                `}
              >
                {}
              </div>
              <div>
                <DownloadBrochure />
              </div>
            </PlanHeadData>
          </PlanCardHeader>
          <div
            css={`
              padding: 14px;
              display: flex;
              flex-direction: column;
              gap: 10px;
            `}
          >
            <PlanCardDataInd>
              <div className="planCardDataInd-head">Plan Details</div>
              <div className="planCardDataInd-text">
                <div>Investment Amount</div>
                <div>₹ {quote?.investment_amount}</div>
              </div>
              <div className="planCardDataInd-text">
                <div>Invest For</div>
                <div>{quote?.pay_term} Years</div>
              </div>
              {getMoneyAs === "L" ? (
                <div className="planCardDataInd-text">
                  <div>Guaranteed Returns After</div>
                  <div>{quote?.policy_term} Years</div>
                </div>
              ) : (
                <div className="planCardDataInd-text">
                  <div>Income For</div>
                  <div>{quote?.income_for} Years</div>
                </div>
              )}

              <div className="planCardDataInd-text">
                <div>Pay Frequency</div>
                <div>{findFrequency(payFrequency)}</div>
              </div>
            </PlanCardDataInd>
            <PlanCardDataInd>
              <div className="planCardDataInd-head">Premium Details</div>
              <div className="planCardDataInd-text">
                <div>Investment Premium</div>
                <div>₹ {quote?.premium}</div>
              </div>
              <div className="planCardDataInd-text">
                <div>Goods & Service Tax</div>
                <div>₹ {quote?.tax_amount}</div>
              </div>
            </PlanCardDataInd>
            <PlanTotalPay>
              <div>Total Premium Payable</div>
              <div>₹ {quote?.total_premium}</div>
            </PlanTotalPay>
            {!hideBuyButton && !doneButton && (
              <ContinueBuy onClick={handleContinueClick}>
                {isRedirectionLoading ? (
                  <>
                    <ButtonLoader />
                  </>
                ) : (
                  "CONTINUE TO BUY"
                )}
              </ContinueBuy>
            )}
            {doneButton && (
              <ContinueBuy onClick={handleClose}>Done</ContinueBuy>
            )}
          </div>
        </PlanCardWrapper>
      )}
    </>
  );
}

export default PlanCard;

function DownloadBrochure() {
  const { data: cartData, isLoading: isCartLoading } = useGetCartQuery();
  if (isCartLoading) return <p>Loading brochure...</p>;
  const { brochure_url } = cartData;
  return (
    <a href={brochure_url} target="_blank" rel="noreferrer">
      <div
        css={`
          color: #333;
          text-decoration: underline;
          cursor: pointer;
          font-size: 12.3px;
          margin-top: 2px;
        `}
      >
        Download Brochure
      </div>
    </a>
  );
}

const PlanCardWrapper = styled.div`
  width: 320px;
  box-shadow: rgb(0 0 0 / 13%) 0px 1px 5px 0px;
  ${mq.sm} {
    width: 100%;
  }
`;

const PlanCardDataInd = styled.div`
  display: flex;
  flex-direction: column;
  gap: 7px;
  font-family: Inter;

  .planCardDataInd-head {
    font-size: 16px;
    border-bottom: 1px solid rgb(222, 222, 255);
    color: var(--primary-color);
    padding-bottom: 12.5px;
  }

  .planCardDataInd-text {
    font-size: 12.2px;
    color: rgb(102, 102, 102);
    display: flex;
    justify-content: space-between;
  }
`;

const PlanCardHeader = styled.div`
  background: var(--tertiary-shade);
  padding: 14px;
  display: flex;
  justify-content: space-between;
  height: fit-content;
  border-bottom: 1px solid rgb(222, 222, 255);
`;

const PlanHeadData = styled.div`
  display: flex;
  width: 60%;
  flex-direction: column;
  align-items: center;
  gap: 2px;
  font-size: 12.3px;
`;

const PlanTotalPay = styled.div`
  background: var(--tertiary-shade);
  padding: 14px;
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const ContinueBuy = styled.button`
  width: 100%;
  height: 45px;
  border: none;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--primary-color);
  color: white;
  cursor: pointer;
`;
