import api from "../../api/api";
import { clearDocumentUploadLoading } from "./ProposalPage.slice";

export const getProposalApi = () => api.get("investment/proposals");
export const saveProposalApi = data => api.post("investment/proposals", data);
export const getLocations = data =>
  api.get(`term/locations/${data.company_alias}?search=${data.pincode}`);
export const submitProposalApi = () =>
  api.post("companies/investment_insurances/proposals");
export const generateBI = data =>
  api.post("/investment/generate-bi", {
    company_alias: data.company_alias,
    flscodedetails: data.flscodedetails,
  });
export const proposalUpdate = data =>
  api.post("investment/proposal-update", {
    status: data,
  });
export const uploadDoc = data => api.post("investment/document-upload", data);
export const getBankDetails = ({ alias, ifsc_code }) =>
  api.get(`term/bank_details?company_alias=${alias}&ifsc_code=${ifsc_code}`);
