import React from "react";
import styled from "styled-components";
import * as mq from "../../../styles/mediaQueries";
import { BsCheck, BsPlus } from "react-icons/bs";
import { useGetCartQuery } from "../services/rider";
import FilterSelect from "../../Filters/FilterDropDown";

function RiderCardMobile({
  cardData,
  handleAddToCart,
  sa_base_plan,
  isLoading,
}) {
  const extractNumbers = (str = "") => String(str).replace(/[^0-9]/g, "");
  const [value, setValue] = React.useState();
  const [edit, setEdit] = React.useState(false);

  const [termOption, setTermOption] = React.useState({});

  const handleCoverChange = e => {
    setValue(e.target.value);
  };

  const { data } = useGetCartQuery({
    product: 504,
    term_insurance_id: 101,
  });

  const handleAddQuote = async item => {
    // const requiredData = sendProductFormat(item);
    await handleAddToCart(item);
  };
  const handleRiderDropDown = e => {
    setTermOption(e.target.value);
  };

  if (isLoading) {
    return <>Loading....</>;
  }
  if (cardData.length === 0) {
    return (
      <>
        <Notfound>Rider Card Not Found</Notfound>
      </>
    );
  }

  return (
    <>
      {/* <Card>
        <RiderCardContainer>
          <RiderCardHeader>
            <RiderCardHeaderLeft>Accidental Death Benifit</RiderCardHeaderLeft>
            <RiderCardHeaderRight>
              <button
                className="btn btn-primary text-primary  border-primary d-flex align-items-center justify-content-around
                  btn-sm fw-bold p-2 bg-white
                  "
              >
                Rs. 1220
                <ButtonIcon>
                  <BsPlus />
                </ButtonIcon>
              </button>
            </RiderCardHeaderRight>
          </RiderCardHeader>
          <RiderCardBody>
            The Insured Amount under this Benefit Option will be payable in the
            event of the death of the Life Insured due to an accident within the
            Benefit Option Term, provided the death occurs within 180 days from
            the accident.
          </RiderCardBody>
          <RiderCardFooter>
            <RiderCover>
              <Label> Rider Cover</Label>

              <InputWrapper // style={{ height: "90%", margin: "0", padding: "0" }}
                style={{
                  margin: "0",
                  padding: "0",
                }}
              >
                <input
                  type=""
                  inputMode="numeric"
                  style={{
                    width: "100px",
                    fontSize: "14px",
                    fontFamily: "Inter",
                    fontWeight: 500,
                    border: "none",
                    outline: "none",
                  }}
                  readOnly={!edit}
                  value={
                    edit ? extractNumbers(value) : getInWordsFunction(value)
                  }
                  maxLength={11}
                  onChange={handleCoverChange}
                  disabled={
                    sa_base_plan === "Y" // ||
                  }
                />
                {edit ? (
                  <i
                    style={{
                      color: "#666",
                      paddingBottom: "5px",
                      fontSize: "16px",
                      paddingLeft: "8px",
                    }}
                    className="fas fa-check-circle"
                    onClick={() => {
                      setEdit(false);
                    }}
                  />
                ) : (
                  <i
                    className="fas fa-edit"
                    onClick={() => setEdit(true)}
                    style={{
                      color: "#666",
                      paddingBottom: "5px",
                      fontSize: "16px",
                      paddingLeft: "8px",
                    }}
                  />
                )}
              </InputWrapper>
            </RiderCover>
            <RiderTerm>
              <Label>Rider Term</Label>
              <RiderDropdown
                disabled={false}
                label="TEST"
                // value={termOption}
                onChange={(code) => handleRiderDropDown(code)}
                options={[
                  {
                    display_name: "30 Year",
                    code: "1",
                  },
                  {
                    display_name: "40 Years",
                    code: "2",
                  },
                  {
                    display_name: "50 Years",
                    code: "2",
                  },
                  {
                    display_name: "60 Years",
                    code: "2",
                  },
                ]}
              />
            </RiderTerm>
            <PayTerm>
              <Label> Pay Term</Label>
              <RiderDropdown
                options={[
                  {
                    display_name: "30 Year",
                    code: "1",
                  },
                  {
                    display_name: "40 Years",
                    code: "2",
                  },
                  {
                    display_name: "50 Years",
                    code: "2",
                  },
                  {
                    display_name: "60 Years",
                    code: "2",
                  },
                ]}
              />
            </PayTerm>
          </RiderCardFooter>
        </RiderCardContainer>
      </Card> */}

      <Card>
        {cardData?.map((item, index) => {
          return (
            <RiderCardContainer key={index}>
              <RiderCardHeader>
                <RiderCardHeaderLeft>{item?.rider_name}</RiderCardHeaderLeft>
                <RiderCardHeaderRight>
                  <button
                    className="btn btn-primary text-primary bg-white border-primary d-flex align-items-center justify-content-around
                  btn-sm fw-bold
                  "
                    onClick={() => handleAddQuote(item)}
                  >
                    Rs. {item?.premium}
                    {item.added ? (
                      <ButtonIcon>
                        <BsCheck className="text-primary" />
                      </ButtonIcon>
                    ) : (
                      <ButtonIcon>
                        <BsPlus className="text-primary" />
                      </ButtonIcon>
                    )}
                    {/* <ButtonIcon>
                    <BsPlus />
                  </ButtonIcon> */}
                  </button>
                </RiderCardHeaderRight>
              </RiderCardHeader>
              <RiderCardBody>{item?.rider_description}</RiderCardBody>
              <RiderCardFooter>
                <RiderCover>
                  <Label> Rider Cover</Label>
                  <InputWrapper // style={{ height: "90%", margin: "0", padding: "0" }}
                    style={{
                      margin: "0",
                      padding: "0",
                    }}
                  >
                    <input
                      type=""
                      inputMode="numeric"
                      style={{
                        width: "100px",
                        fontSize: "14px",
                        fontWeight: "500",
                        border: "none",
                        outline: "none",
                      }}
                      readOnly={!edit}
                      value={
                        edit ? extractNumbers(value) : getInWordsFunction(value)
                      }
                      maxLength={11}
                      onChange={handleCoverChange}
                      disabled={
                        sa_base_plan === "Y" // ||
                      }
                    />
                    {edit ? (
                      <i
                        style={{
                          color: "#666",
                          paddingBottom: "5px",
                          fontSize: "16px",
                          paddingLeft: "8px",
                        }}
                        className="fas fa-check-circle"
                        onClick={() => {
                          setEdit(false);
                        }}
                      />
                    ) : (
                      <i
                        className="fas fa-edit"
                        onClick={() => setEdit(true)}
                        style={{
                          color: "#666",
                          paddingBottom: "5px",
                          fontSize: "16px",
                          paddingLeft: "8px",
                        }}
                      />
                    )}
                  </InputWrapper>
                </RiderCover>
                <RiderTerm>
                  <Label> Rider Term</Label>

                  <RiderDropdown
                    value={termOption}
                    onChange={code => handleRiderDropDown(code)}
                    options={[
                      {
                        display_name: "30 Year",
                        code: "1",
                      },
                      {
                        display_name: "40 Years",
                        code: "2",
                      },
                      {
                        display_name: "50 Years",
                        code: "2",
                      },
                      {
                        display_name: "60 Years",
                        code: "2",
                      },
                    ]}
                  />
                </RiderTerm>
                <PayTerm>
                  <Label> Pay Term</Label>
                  <RiderDropdown
                    options={[
                      {
                        display_name: "30 Year",
                        code: "1",
                      },
                      {
                        display_name: "40 Years",
                        code: "2",
                      },
                      {
                        display_name: "50 Years",
                        code: "2",
                      },
                      {
                        display_name: "60 Years",
                        code: "2",
                      },
                    ]}
                  />
                </PayTerm>
              </RiderCardFooter>
            </RiderCardContainer>
          );
        })}
      </Card>
    </>
  );
}
function getInWordsFunction(value) {
  function calcFunction(value) {
    return value.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];
  }
  if (!value) return null;
  value = String(value);
  const length = value.length;
  if (length >= 8) {
    return `${calcFunction(value / Math.pow(10, 7))} Cr`;
  } else if (length >= 6) {
    return `${calcFunction(value / Math.pow(10, 5))} Lac`;
  } else if (length >= 4) {
    return `${calcFunction(value / Math.pow(10, 3))} Th`;
  } else return value;
}

export default RiderCardMobile;

const Notfound = styled.div`
  display: flex;
  width: 60%;
  justify-content: center;
  align-items: center;
  font-size: 25px;
`;

const Card = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  width: 70%;
  ${"" /* flex-1; */}
  ${mq.sm} {
    display: flex;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    margin-top: 200px;
    margin-bottom: 100px;
  }
`;
const RiderCardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  border-radius: 10px;
  border: solid 1px #afafaf;
  padding: 12px;
  padding-bottom: 0px;
  ${"" /* padding: 10px; */}
  margin: 10px;
  width: 400px;
  ${"" /* width: 320px; */}
  ${mq.sm} {
    border-radius: 5px;
    width: 100%;
    margin: 2px;
    margin-top: 1em;
    padding: 14px;
  }
  ${"" /* box-shadow: 0px 0px 12px 0 #707070; */}
`;
const RiderCardHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  ${mq.sm} {
  }
`;
const RiderCardHeaderLeft = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 20px;
  font-weight: 600;
  ${mq.sm} {
    font-size: 14px;
  }
`;
const RiderCardHeaderRight = styled.div`
  display: flex;
  flex-direction: row;
  font-weight: 600;
  font-size: 18px;
  ${mq.sm} {
  }
`;

const RiderCardBody = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 15px;
  font-weight: 500;
  color: #000;
  margin-top: 4px;
  padding-top: 12px;
  padding-bottom: 12px;

  ${mq.sm} {
    font-size: 12px;
  }
`;

const ButtonIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: 600;
  border: solid 1px #0056fe;
  border-radius: 50%;
  margin-left: 5px;
`;

const RiderCardFooter = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-left: 18px;
  padding-right: 18px;
  ${mq.sm} {
    display: none;
  }
`;

const Label = styled.div`
  font-size: 14px;
  color: #0056fe;
`;
const RiderCover = styled.div``;
const RiderTerm = styled.div``;
const PayTerm = styled.div``;

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: ${props => (props.edit ? "50px" : "40px")};
  justify-content: ${props => props.flexEnd && "flex-end"};
  justify-content: ${props => props.flexCenter && "center"};
  margin-top: ${props => props.flexEnd && "10px"};
  position: relative;
  margin-bottom: 24px;
  padding: ${props => (props.button ? "10px" : "")};
`;

function RiderDropdown({ disabled = false, ...props }) {
  return (
    <FilterSelect
      {...props}
      searchable
      inputStyle={{
        backgroundColor: "#fff",
        padding: 0,
        textAlign: "left",
        height: "auto",
        fontSize: "0.76rem",
        margin: 0,
      }}
      arrowStyle={{
        right: 0,
        position: "absolute",
        display: disabled ? "none" : "static",
      }}
      wrapperStyle={{
        padding: 0,
        fontSize: "0.873em",
        marginLeft: "-0.397em",
        marginTop: "0.3em",
        pointerEvents: disabled ? "none" : "all",
      }}
      listStyle={{ width: "100%", left: 0 }}
    />
  );
}
