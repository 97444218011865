import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import React, { useState } from "react";
import { styled } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import useGetFilterOptions from "../../../../../../../custom-hooks/useGetFilterOptions";
import useSetFilter from "../../../../../../../custom-hooks/useSetFilter";
import { changeFilter } from "../../../../../../Filters/filterSlice";
import { clearQuotes } from "../../../../../Quotes.slice";
import * as mq from "../../../../../../../styles/mediaQueries";

function ReturnsAfter({ setShow }) {
  const [returnsAfterValue, setReturnsAfterValue] = useState(
    useSelector(state => state.filters.returnsAfter),
  );
  const returnsAfterOptions = useGetFilterOptions({ filter: "policy_term" });
  useSetFilter({ filter: "returnsAfter", value: returnsAfterValue });
  const dispatch = useDispatch();
  return (
    <>
      <CustomGroup
        color="primary"
        value={returnsAfterValue}
        exclusive
        onChange={e => {
          setReturnsAfterValue(e.target.value);
          setShow(false);
        }}
      >
        {returnsAfterOptions.length > 0 &&
          returnsAfterOptions.map((item, index) => (
            <CustomButton key={index} value={item.code}>
              {item.display_name}
            </CustomButton>
          ))}
      </CustomGroup>
    </>
  );
}
const CustomGroup = styled(ToggleButtonGroup)({
  "&.MuiToggleButtonGroup-root": {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
    gap: "5px",
  },
});

const CustomButton = styled(ToggleButton)({
  width: "48%",
  padding: "5px",
  border: "none",
  outline: "none",
  borderRadius: "5px !important",
  [mq.md]: {
    fontSize: "18px",
    padding: "10px",
  },
  [mq.sm]: {
    fontSize: "12px",
  },
  fontFamily: "Inter",
  fontWeight: "600",
  backgroundColor: "rgba(79, 92, 111, 0.1)",
  textTransform: "lowercase",
  "&.Mui-selected, &.Mui-selected:hover": {
    color: "white",
    backgroundColor: "var(--primary-color)",
  },
});

export default ReturnsAfter;
