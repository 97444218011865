import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import useUrlQuery from "../custom-hooks/useUrlQuery";
import {
  riderApi,
  useGetCartQuery,
  useGetRedirectionMutation,
} from "../pages/rider/services/rider";
import { toast } from "react-toastify";
import { getBi } from "../pages/RiderPageNew/RiderPlanCard/RiderNewPlanCard";
import { setRedirectionTriggered } from "../pages/ProposalPage/ProposalPage.slice";
export function riderResponseFormat(sendRider) {
  return {
    id: sendRider?.rider_id,
    premium: sendRider?.premium,
    policy_term: sendRider?.policy_term,
    investment_amount: sendRider?.investment_amount,
    service_tax: sendRider?.tax_amount,
    total_premium: sendRider?.net_premium,
    extra_input: {
      isStaff: "No",
      existing_customer: "No",
      secondary_dob: "20/11/1997",
      secondary_tobacco: "NS",
      secondary_gender: "F",
    },
  };
}

export function sendUpdatedProductFormat(product, cart) {
  const riders = cart.riders || [];
  return {
    total_premium: product?.total_premium,
    tax_amount: product?.tax_amount,
    term_coverupto: product?.term_coverupto,
    term_pay_till: product?.term_pay_till,
    term_sum_assured: product?.term_sum_assured,
    premium: product?.premium,
    service_tax: product?.tax_amount,
    term_insurance_id: product?.term_insurance_id,
    product_id: product?.product.id,
    riders,
  };
}

export function sendProductFormat(product) {
  const riders = product?.riders || [];
  return {
    total_premium: product?.total_premium,
    investment_amount: product?.investment_amount,
    tax_amount: product?.tax_amount,
    pay_term: product?.pay_term,
    policy_term: product?.policy_term,
    investment_insurance_id: product?.investment_insurance_id,
    product_id: product?.product?.id,
    premium: product?.premium,
    service_tax: product?.service_tax,
    riders: riders,
  };
}
export function sendRiderFormat({
  sum_assured,
  policy_term,
  premiumPayingTerm,
  max_cover_amount,
  min_cover_amount,
  ...rider
}) {
  return {
    id: rider.rider_id,
    premium: rider.premium,
    // term_policy_term: policy_term || rider.default_policy_term,
    pay_term: premiumPayingTerm || rider.default_ppt || 20,
    // term_limited_pay_term: premiumPayingTerm || rider.default_ppt || 0,
    investment_amount: sum_assured || rider.investment_amount || 0,
    policy_term: policy_term || rider.default_policy_term || 5,
    // term_sum_assured: sum_assured || rider.default_sum_assured || 0,
    service_tax: rider.tax_amount,
    total_premium: rider.net_premium || rider.total_premium,
    rider_name: rider.rider_name || rider.name,
    name: rider.rider_name || rider.name,
    rider_id: rider.rider_id,
    max_cover_amount,
    min_cover_amount,
    recalc_rider: rider.recalc_rider,
    rider_shortname: rider.rider_shortname || rider.short_name,
  };
}
export function redirectToIc(redirectionData) {
  if (redirectionData) {
    const { redirection_url, redirection_method, redirection_params } =
      redirectionData;

    const form = document.createElement("form");
    if (redirection_method === "POST") {
      form.setAttribute("action", redirection_url);
      form.setAttribute("method", redirection_method);
      Object.entries(redirection_params).forEach(([key, value]) => {
        const inputData = document.createElement("input");
        inputData.name = key;
        inputData.value = value;
        form.appendChild(inputData);
      });
      document.body.appendChild(form);
      form.submit();

      document.body.removeChild(form);
    } else if (redirection_method === "GET") {
      window.open(redirection_url, "_self");
    }
  }
}

export function useRedirection() {
  const navigate = useNavigate();
  const enquiryId = useUrlQuery("enquiryId");
  const [getRedirection, { isLoading, status: redirectionApiStatus }] =
    useGetRedirectionMutation();
  const { data: cartData, isLoading: isCartLoading } = useGetCartQuery();
  const dispatch = useDispatch();
  const { selectedQuote } = useSelector(state => state.quotes);
  const { distributor_summary } = useSelector(state => state.proposalForm);
  const isSell = selectedQuote?.journey_name === "S";
  if (isCartLoading || !cartData) return { isCartLoading };

  const {
    investment_insurance_id: investmentInsuranceId,
    product: {
      id: productId,
      company: { alias: company_alias },
    },
  } = cartData;
  console.log({ company_alias });
  function redirection() {
    getRedirection({
      company_alias: company_alias,
      product_id: productId,
      investment_insurance_id: investmentInsuranceId,
    }).then(res => {
      if (res.data && redirectionApiStatus !== "rejected") {
        const { data } = res.data;
        if (
          data[0] &&
          ["R", "PF"].includes(data[0].journey_type) &&
          data[0].redirection_url
        ) {
          redirectToIc(res.data.data[0]);
          return;
        } else {
          dispatch(riderApi.util.invalidateTags(["Enquiry"]));
          navigate(`/investment/proposal?enquiryId=${enquiryId}`);
        }
      } else {
        toast.error("Something went wrong", {
          hideProgressBar: true,
          toastId: "failed1",
          autoClose: 3000,
          position: "bottom-center",
          theme: "colored",
        });
      }
    });
  }

  const handleClick = () => {
    if (
      company_alias === "icici_pru" ||
      company_alias === "pnb_metlife" ||
      company_alias === "tata_aia" ||
      company_alias === "hdfc_life"
    ) {
      if (isSell && company_alias === "hdfc_life") {
        if (!distributor_summary) {
          dispatch(setRedirectionTriggered(true));
          return;
        }
      }
      dispatch(getBi(company_alias)).then(data => {
        if (data) {
          const link = document.createElement("a");
          link.href = data;
          link.download = "BenefitIllustration";
          link.target = "_blank";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);

          redirection();
        } else console.log("BI Generate Error");
      });
    } else redirection();
  };

  return { isLoading, handleClick, isCartLoading, cartData };
}

export const extractNumbers = (str = "") => String(str).replace(/[^0-9]/g, "");

export function getOrdinalNumber(val) {
  let str = val.toString();
  if (str[str.length - 1] === "1") {
    return str + "st";
  }
  if (str[str.length - 1] === "2") {
    return str + "nd";
  }
  if (str[str.length - 1] === "3") {
    return str + "rd";
  }
  return str + "th";
}

export function getCurrentYear() {
  let year = new Date().getFullYear();
  return year;
}

function calc(value) {
  var with2Decimals = value.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];
  return with2Decimals;
}
export const getInWords = value => {
  if (value == null) return null; // Checks for both null and undefined. Reference: https://i.stack.imgur.com/rpq9d.jpg
  value = String(value);
  const length = value.length;
  if (length >= 8) {
    return `${calc(value / Math.pow(10, 7))} Cr`;
  } else if (length >= 6) {
    return `${calc(value / Math.pow(10, 5))} Lac`;
  } else if (length >= 4) {
    return `${calc(value / Math.pow(10, 3))} K`;
  } else return value;
};

export const ciRiders = ["CI", "CIP"];

export const isCIRider = rider => {
  if (!rider) return false;
  const { rider_shortname } = rider;

  return ciRiders.includes(rider_shortname);
};

const isNumber = function isNumber(value) {
  return typeof value === "number" && isFinite(value);
};

const isNumberObject = function isNumberObject(n) {
  return Object.prototype.toString.apply(n) === "[object Number]";
};

const isCustomNumber = function isCustomNumber(n) {
  return isNumber(n) || isNumberObject(n);
};
export const getValue = num => {
  if (isCustomNumber(num)) {
    if (String(num).length >= 8) {
      let value = num / 10000000;
      return `${+(Math.round(value + "e+2") + "e-2")} Crore`;
    } else if (String(num).length >= 6) {
      let value = num / 100000;
      return `${+(Math.round(value + "e+2") + "e-2")} Lacs`;
    }
  }
  return num;
};
