import styled from "styled-components";
import React, { useRef, useState } from "react";
// import check from "../../../assets/images/check.png";
import wrong from "../../src/assets/images/wrong.png";
// import useOutsideClickRef from "../../../custom-hooks/useOutsideClickRef";
import { Modal } from "react-bootstrap";
import "styled-components/macro";
// import RiderDescription from "./RiderDescription";
// import { amount } from "../../../utils";
// import ButtonLoader from "../../../components/CardSkeletonLoader/ButtonLoader/ButtonLoader";
import { useDispatch, useSelector } from "react-redux";
// import {
//   setDiabeticFalse,
//   setDiabeticTrue,
// } from "../../product-page/QuotesPage/Quotes.slice";
import { Switch } from "@mui/material";
import {
  setDiabeticFalse,
  setDiabeticTrue,
} from "../pages/QuotesPage/Quotes.slice";
import useOutsideClickRef from "../custom-hooks/useOutsideClickRef";
import { amount } from "../utils";
import RiderDescription from "../pages/RiderPageNew/RiderDescription";
import ButtonLoader from "./Loaders/ButtonLoader/ButtonLoader";

const CardPopup = ({
  handleClose,
  handleContinue,
  show,
  rider,
  handleCICountChange = () => {},
  isLoading = false,
  isAdded,
  CIVariant,
}) => {
  const dispatch = useDispatch();
  const { net_premium } = rider;
  const { isDiabetic } = useSelector(state => state.quotes);
  const [check, setCheck] = useState(isDiabetic);
  const ref = useRef();
  useOutsideClickRef(ref, () => {
    handleClose();
  });

  const handleCheck = () => {
    if (isDiabetic == true) {
      dispatch(setDiabeticFalse());
    } else if (isDiabetic == false) {
      dispatch(setDiabeticTrue());
    }
  };

  const handleCIChange = evt => {
    const { value } = evt.target;
    if (value === 64) {
      setInclude(true);
    }
    handleCICountChange(parseInt(value));
  };
  const [include, setInclude] = useState(CIVariant === 64);

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Body
        css={`
          padding: 0;
          padding-bottom: 1em;
        `}
      >
        <div
          css={`
            width: 498px;
            display: flex;
            align-items: center;
            padding: 10px 15px;
            border-radius: 8px;
            background-color: #f3f4f9;

            @media (max-width: 768px) {
              width: 320px;
            }
          `}
        >
          {/* <p>Accidental death benefit</p> */}
          <RiderHead>{rider.rider_name}</RiderHead>

          <CloseButton
            onClick={() => {
              handleClose();
            }}
          >
            <img
              css={`
                height: 25px;
                padding: 0px;
                margin: -6px;
              `}
              src={wrong}
              alt=""
            />
          </CloseButton>
        </div>
        <Container>
          <div
            css={`
              margin-bottom: 5px;
              padding-bottom: 10px;
              border-bottom: 1px solid lightgray;
            `}
          >
            {/* <strong
              css={`
                font-size: 13px;
                color: gray;
                line-height: 1.5 !important;
              `}
            >
              In case of an unfortunate event of death due to an accident,
              additonal cover amount is paid to the nominee.
            </strong> */}
            <RiderDescription
              rider={rider}
              css={`
                font-size: 13px;
                color: gray;
                line-height: 1.5 !important;
              `}
            />
          </div>

          <div
            css={`
              display: flex;
              justify-content: space-between;
              align-items: center;
              padding: 10px 20px;
            `}
          >
            <strong
              css={`
                font-size: 13px;
                color: gray;
                line-height: 1.5 !important;
              `}
            >
              Are you diabetic?
            </strong>
            <ToggleComp>
              <div>No</div>
              <Switch
                size="large"
                color="error"
                checked={isDiabetic}
                onClick={handleCheck}
              />
              <div>Yes</div>
            </ToggleComp>
            {/* <RadioButon color={isDiabetic}>
              <strong
                css={`
                  cursor: pointer;
                  font-size: 12px;
                  padding: 10px;
                `}
                onClick={() => {
                  dispatch(setDiabeticTrue());
                }}
              >
                Yes
              </strong>

              {isDiabetic === true ? (
                <img
                  css={`
                    height: 25px;
                    transition: all 0.2s ease-in;
                  `}
                  src={check}
                  alt=""
                />
              ) : (
                <img
                  css={`
                    height: 25px;
                    transition: all 0.2s ease-in;
                  `}
                  src={wrong}
                  alt=""
                />
              )}

              <strong
                css={`
                  cursor: pointer;
                  font-size: 12px;
                  padding: 10px;
                `}
                onClick={() => {
                  dispatch(setDiabeticFalse());
                }}
              >
                No
              </strong>
            </RadioButon> */}
          </div>

          {!isDiabetic ? (
            <div>
              {rider.rider_shortname !== "WOP" && (
                <div
                  css={`
                    display: flex;
                    flex-direction: column;
                    border: 1px solid lightgray;
                    padding: 5px 8px;
                  `}
                >
                  <label
                    css={`
                      font-size: 13px;
                      color: gray;
                      margin: 4px;
                    `}
                  >
                    Select Critical Illness
                  </label>
                  <select
                    css={`
                      background-color: unset;
                      border: unset;
                      font-size: 12px;
                    `}
                    onChange={handleCIChange}
                    value={CIVariant}
                  >
                    <option value={22}>22 Critical Illness</option>
                    <option value={64}>64 Critical Illness</option>
                  </select>
                </div>
              )}

              <div>
                {rider.rider_shortname !== "CI" &&
                  rider.rider_shortname !== "WOP" && (
                    <div
                      css={`
                        display: flex;
                        align-items: center;
                        justify-content: start;
                        padding: 10px 0px;
                      `}
                    >
                      <input
                        type="checkbox"
                        onChange={() => {
                          if (CIVariant !== 64) setInclude(!include);
                        }}
                        checked={
                          rider.rider_shortname === "CIP" ? true : include
                        }
                      />
                      <strong
                        css={`
                          font-size: 13px !important;
                          margin-left: 10px;
                        `}
                      >
                        Include Total and Permanent Disability
                      </strong>
                    </div>
                  )}

                <button
                  css={`
                    height: 50px;
                    width: 100%;
                    border: unset;
                    margin: 10px 0px;
                    color: #fff;
                    font-size: 16px;
                    border-radius: 2px;
                    background-color: var(--primary-color);
                    font-family: "Inter";
                  `}
                  onClick={() => {
                    handleContinue({ ci_variant: CIVariant });
                    handleClose();
                  }}
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <ButtonLoader />
                  ) : (
                    `${isAdded ? "REMOVE" : "ADD"} RIDER (${amount(
                      net_premium,
                    )})`
                  )}
                </button>
              </div>
            </div>
          ) : (
            <div
              css={`
                font-size: 13px;
                color: gray;
                line-height: 1.5 !important;
                border-top: 1px solid lightgray;
                padding-top: 21px;
                margin-top: 5px;
              `}
            >
              Following riders of Max Life Insurance are not available for
              diabetic: <br />
              <ul>
                <li>Critical Illness Benefit</li>
                <li>Critical Illness Plus</li>
                <li>Waiver of Premium</li>
              </ul>
            </div>
          )}
        </Container>
      </Modal.Body>
    </Modal>
  );
};

export default CardPopup;
const RadioButon = styled.div`
  width: 150px;
  height: 50px;
  background-color: ${props =>
    props.color ? "var(--primary-color)" : "#eb4f37"};
  transition: all 0.2s ease;
  border-radius: 30px;
  padding: 10px 15px;
  border: unset;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
`;
const CloseButton = styled.button`
  width: 26px;
  height: 26px;
  position: absolute;
  right: 12px;
  top: 12px;
  border: 1px solid #dce2ea;
  border-radius: 50%;
  font-size: 16px;
  & i {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;
const Container = styled.div`
  padding: 10px 20px;
  /* & p {
    font-weight: 400;
    font-family: "pf_handbook_proregular";
    font-size: 20px;
  } */
  & span {
    text-transform: capitalize;
    font-weight: 600;
  }

  @media (max-width: 767px) {
    padding: 14px 16px;
    width: 95%;
    & p {
      font-weight: 400;
      font-family: "pf_handbook_proregular";
      font-size: 18px;
      line-height: 1.4;
    }
  }
`;

const ToggleComp = styled.div`
  display: flex;
  padding: 10px 0px;
  gap: 10px;
  font-size: 14px;
  align-items: center;
`;

const RiderHead = styled.div`
  font-size: 14px;
  font-family: "Inter-Medium";
  font-weight: 500;
  padding: 10px;
`;
