import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import React, { useState } from "react";
import { styled } from "@material-ui/core";
import useGetFilterOptions from "../../../../../../../custom-hooks/useGetFilterOptions";
import { useSelector } from "react-redux";
import * as mq from "../../../../../../../styles/mediaQueries";
import useSetFilter from "../../../../../../../custom-hooks/useSetFilter";

function IncomeFor({ setShow }) {
  const [incomeForValue, setIncomeForValue] = useState(
    useSelector(state => state.filters.incomeFor),
  );
  const incomeForOptions = useGetFilterOptions({ filter: "income_for" });
  useSetFilter({ filter: "incomeFor", value: incomeForValue });
  return (
    <>
      <CustomGroup
        color="primary"
        value={incomeForValue}
        exclusive
        onChange={e => {
          setIncomeForValue(e.target.value);
          setShow(false);
        }}
      >
        {incomeForOptions.length > 0 &&
          incomeForOptions.map((item, index) => (
            <CustomButton key={index} value={item.code}>
              {item.display_name}
            </CustomButton>
          ))}
      </CustomGroup>
    </>
  );
}

const CustomGroup = styled(ToggleButtonGroup)({
  "&.MuiToggleButtonGroup-root": {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
    gap: "5px",
  },
});

const CustomButton = styled(ToggleButton)({
  width: "48%",
  padding: "5px",
  border: "none",
  outline: "none",
  borderRadius: "5px !important",
  fontSize: "12px",
  fontFamily: "Inter",
  fontWeight: "600",
  backgroundColor: "rgba(79, 92, 111, 0.1)",
  textTransform: "lowercase",
  "&.Mui-selected, &.Mui-selected:hover": {
    color: "white",
    backgroundColor: "var(--primary-color)",
  },
  [mq.md]: {
    fontSize: "18px",
    padding: "10px",
  },
  [mq.sm]: {
    fontSize: "12px",
  },
});

export default IncomeFor;
