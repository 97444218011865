import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import ButtonLoader from "../../../../../components/Loaders/ButtonLoader/ButtonLoader";
import {
  checkDocType,
  checkSize,
  convert,
} from "../../../../DocumentUpload/doc-utils";
import {
  useGetApplicationNumberQuery,
  useGetCartQuery,
  useGetEnquiriesQuery,
  useGetProposalDataQuery,
} from "../../../../rider/services/rider";
import { addressProofOptions } from "../../../components/options/TataAia/addressOptions";

import { incomeProofOptions } from "../../../components/options/TataAia/eduAndOccupOptions";
import {
  age_proof,
  bankProofOptions,
  identityProofOptions,
} from "../../../components/options/TataAia/proposerOptions";
import Selectt from "../../../components/Selectt";
import Title from "../../../components/Title";
import {
  clearDocumentUploadLoading,
  initDocumentUploadLoading,
} from "../../../ProposalPage.slice";
import {
  ErrorMsg,
  FileInputWrapper,
  UploaderBody,
  UploadWrap,
} from "../../../ProposalPage.style";
import { uploadDoc } from "../../../serviceApi";

const TataDocumentUpload = ({ disable, setDisable }) => {
  const { data: cartData } = useGetCartQuery();
  const appData = useGetApplicationNumberQuery();
  const appNo = appData?.data?.data.proposal_reference_id;
  const { data: proposalData, isLoading: isProposalDataLoading } =
    useGetProposalDataQuery();
  const { data: enquiryData } = useGetEnquiriesQuery();
  const [errors, setErrors] = useState({});
  const [previews, setPreviews] = useState({});
  const [uploaded, setUploaded] = useState("");
  const [documents, setDocuments] = useState({});
  const currentDate = moment().format("DDMMYYYY");
  const [docName, setDocName] = useState({
    ip: `InsuredPhoto_${currentDate}_1.pdf`,
    id_proof: `IdProof_${currentDate}_2.pdf`,
    age_proof: `AgeProof_${currentDate}_3.pdf`,
    address_proof: `AddressProof_${currentDate}_4.pdf`,
    income_proof: `IncomeProof_${currentDate}_5.pdf`,
    business_cert: `BusinessCertificate_${currentDate}_6.pdf`,
    other_doc: `OtherProof_${currentDate}_7.pdf`,
    bank_proof: `BankProof_${currentDate}_8.pdf`,
  });
  const { isDocumentUploadLoading } = useSelector(state => state.proposalForm);
  const dispatch = useDispatch();
  // const isDocumentUploadLoading = false;
  function createPreview(fileInput) {
    let blob = new Blob(fileInput?.target?.files, {
      type: fileInput?.target?.files[0]?.type,
    });

    return URL.createObjectURL(blob);
  }
  React.useEffect(() => {
    if (!isProposalDataLoading && proposalData) {
      setDocuments({
        ...documents,
        ["Identity Proof"]: {
          ...(documents["Identity Proof"] ? documents["Identity Proof"] : {}),
          file_type:
            proposalData?.data["Personal Details"]?.identity_proof_type,
          doc_type: "FORM",
          doc_id: proposalData?.data["Personal Details"]?.identity_proof_type,
        },
        ["Age Proof"]: {
          ...(documents["Age Proof"] ? documents["Age Proof"] : {}),
          file_type: proposalData?.data["Personal Details"]?.age_proof_type,
          doc_type: "FORM",
          doc_id: proposalData?.data["Personal Details"]?.age_proof_type,
        },
        ["Residential Address Proof"]: {
          ...(documents["Residential Address Proof"]
            ? documents["Residential Address Proof"]
            : {}),
          file_type: proposalData?.data["Address Details"]?.address_proof,
          doc_type: "FORM",
          doc_id: proposalData?.data["Address Details"]?.address_proof,
        },
        ["Income Proof"]: {
          ...(documents["Income Proof"] ? documents["Income Proof"] : {}),
          file_type:
            proposalData?.data["Education and Occupation Details"]
              ?.income_proof,
          doc_type: "FORM",
          doc_id:
            proposalData?.data["Education and Occupation Details"]
              ?.income_proof,
        },

        ["Bank Proof"]: {
          ...(documents["Bank Proof"] ? documents["Bank Proof"] : {}),
          file_type: proposalData?.data["Bank Details"]?.bank_details,
          doc_type: "FORM",
          doc_id: proposalData?.data["Bank Details"]?.bank_details,
        },
      });
    }
  }, [isProposalDataLoading, proposalData]);
  useEffect(() => {
    if (
      documents["Insured Photo"]?.upload_file === undefined ||
      documents["Residential Address Proof"]?.upload_file === undefined ||
      documents["Income Proof"]?.upload_file === undefined ||
      documents["Age Proof"]?.upload_file === undefined ||
      documents["Identity Proof"]?.upload_file === undefined ||
      // documents["Other Document"]?.upload_file === undefined ||
      documents["Bank Proof"]?.upload_file === undefined
    ) {
      return setDisable(prev => ({
        ...prev,
        ["Insured Photo"]: true,
        ["Age Proof"]: true,
        ["Residential Address Proof"]: true,
        ["Income Proof"]: true,
        ["Identity Proof"]: true,
        // ["Other Document"]: true,
        ["Bank Proof"]: true,
      }));
    }
  }, []);
  function setUploadedText(item) {
    return (document.getElementById(item).innerHTML = "Uploaded");
  }
  const onSubmit = async (e, item) => {
    const newData = await convert(documents[item].upload_file);
    try {
      dispatch(initDocumentUploadLoading());
      const { data } = await uploadDoc({
        company_alias: cartData?.product?.company?.alias,
        application_no: appNo,
        upload_file_name: documents[item]?.upload_file_name,
        upload_file_id: documents[item]?.upload_file_id,
        upload_file: newData.split(",")[1],
        doc_id: documents[item]?.doc_id,
        doc_type: documents[item]?.doc_type,
        doc_format: documents[item]?.doc_format,
      });
      dispatch(clearDocumentUploadLoading());
      setDisable(prev => ({ ...prev, [item]: false }));
      toast.success(`${item} Uploaded!`, {
        toastId: item,
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
      });
      // setUploadedText(item);
    } catch (err) {
      console.log("error", err, isDocumentUploadLoading);
      dispatch(clearDocumentUploadLoading());

      toast.error(`${item} Upload Failed! ${err}`, {
        toastId: `${item}_error`,
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
      });
    }
  };
  function removeDocument(document) {
    setDocuments(prev => {
      let temp = { ...prev };
      temp[document].upload_file = null;
      temp[document].upload_file_id = null;
      temp[document].upload_file_name = null;
      return temp;
    });

    setPreviews(prev => {
      let temp = { ...prev };
      temp[document] = {};
      return temp;
    });

    setDisable(prev => ({ ...prev, [document]: true }));
  }
  return (
    <div className="doc-form__wrapper my-4">
      {/* ================================= Insured Photo ============================ */}
      <UploaderBody
        className="col-md-12 select_wrapper"
        active={documents["Insured Photo"]?.upload_file ? true : false}
      >
        <div className="upload__body">
          <UploadWrap>
            <Title label={"Insured Photo"} />
          </UploadWrap>
          <FileInputWrapper className="col-md-6">
            <span className="w-100 file_name_container">
              {docName.ip}
              <input
                type="file"
                name="myfile"
                id={docName.ip}
                onClick={e => {
                  e.target.value = null;
                }}
                onChange={e => {
                  if (
                    e.target.files[0] &&
                    (!checkSize(e, 5) || !checkDocType(e, ["jpg"]))
                  ) {
                    setErrors({
                      ...errors,
                      ["Insured Photo"]: "File type or size not allowed",
                    });
                  } else {
                    const name = `${appNo}_InsuredPhoto_${currentDate}_1.${
                      e.target.files[0]?.name?.split(".")?.reverse()[0]
                    }`;
                    const doc_format = e.target.files[0]?.name
                      ?.split(".")
                      ?.reverse()[0];
                    setDocName(prev => ({
                      ...prev,
                      ip: name,
                    }));
                    setErrors({
                      ...errors,
                      ["Insured Photo"]: "",
                    });
                    setDocuments({
                      ...documents,
                      ["Insured Photo"]: {
                        ...(documents["Insured Photo"]
                          ? documents["Insured Photo"]
                          : {}),
                        upload_file: e.target.files[0],
                        upload_file_id: 1,
                        doc_id: "1022010142",
                        doc_type: "PHOTOGRAPH",
                        upload_file_name: name,
                        doc_format,
                      },
                    });

                    setPreviews({
                      ...previews,
                      ["Insured Photo"]: {
                        ...(previews["Insured Photo"]
                          ? previews["Insured Photo"]
                          : {}),
                        blob_file: createPreview(e),
                      },
                    });
                  }
                }}
              />
            </span>
          </FileInputWrapper>

          {!documents["Insured Photo"]?.upload_file ? (
            <label className="Choose_file_btn" htmlFor={docName.ip}>
              Choose a file
            </label>
          ) : (
            <button
              disabled={disable["Insured Photo"] === false}
              className={`col-4 Choose_file_btn btn `}
              onClick={e => {
                onSubmit(e, "Insured Photo");
                setUploaded("Insured Photo");
              }}
            >
              {uploaded === "Insured Photo" && isDocumentUploadLoading ? (
                <ButtonLoader />
              ) : disable["Insured Photo"] === false ? (
                <p id="Insured Photo">Uploaded</p>
              ) : (
                <p id="Insured Photo">Upload</p>
              )}
            </button>
          )}

          {previews["Insured Photo"]?.blob_file && (
            <a
              className={`col-4 upload_btn btn `}
              href={previews["Insured Photo"]?.blob_file}
              target="_blank"
            >
              Preview
            </a>
          )}

          {documents["Insured Photo"]?.upload_file && (
            <i
              className="fas fa-trash-alt trash-icon"
              onClick={() => removeDocument("Insured Photo")}
            />
          )}
        </div>
        {errors["Insured Photo"] && (
          <ErrorMsg rel>{errors["Insured Photo"]}</ErrorMsg>
        )}
      </UploaderBody>
      {/* ================================= ID ============================ */}
      <UploaderBody
        className="col-md-12 select_wrapper"
        active={documents["Identity Proof"]?.upload_file ? true : false}
      >
        <div className="upload__body">
          <UploadWrap>
            <Selectt
              readOnly
              options={identityProofOptions}
              label={"Identity Proof"}
              selectedValue={documents["Identity Proof"]?.file_type}
              noSearch
              handleChange={value =>
                setDocuments({
                  ...documents,
                  ["Identity Proof"]: {
                    ...(documents["Identity Proof"]
                      ? documents["Identity Proof"]
                      : {}),
                    file_type: value,
                  },
                })
              }
            />
          </UploadWrap>
          <FileInputWrapper className="col-md-6">
            <span className="w-100 file_name_container">
              {docName.id_proof}
              <input
                type="file"
                name="myfile"
                id={docName.id_proof}
                onClick={e => {
                  e.target.value = null;
                }}
                onChange={e => {
                  if (
                    e.target.files[0] &&
                    (!checkSize(e, 5) || !checkDocType(e, ["pdf", "jpg"]))
                  ) {
                    setErrors({
                      ...errors,
                      ["Identity Proof"]: "File type or size not allowed",
                    });
                  } else {
                    const name = `${appNo}_IdentityProof_${currentDate}_2.${
                      e.target.files[0]?.name?.split(".")?.reverse()[0]
                    }`;
                    const doc_format = e.target.files[0]?.name
                      ?.split(".")
                      ?.reverse()[0];
                    setDocName(prev => ({
                      ...prev,
                      id_proof: name,
                    }));
                    setErrors({
                      ...errors,
                      ["Identity Proof"]: "",
                    });
                    setDocuments({
                      ...documents,
                      ["Identity Proof"]: {
                        ...(documents["Identity Proof"]
                          ? documents["Identity Proof"]
                          : {}),
                        upload_file: e.target.files[0],
                        upload_file_id: 2,
                        upload_file_name: name,
                        doc_format,
                      },
                    });

                    setPreviews({
                      ...previews,
                      ["Identity Proof"]: {
                        ...(previews["Identity Proof"]
                          ? previews["Identity Proof"]
                          : {}),
                        blob_file: createPreview(e),
                      },
                    });
                  }
                }}
              />
            </span>
          </FileInputWrapper>

          {!documents["Identity Proof"]?.upload_file ? (
            <label className="Choose_file_btn" htmlFor={docName.id_proof}>
              Choose a file
            </label>
          ) : (
            <button
              disabled={disable["Identity Proof"] === false}
              className={`col-4 Choose_file_btn btn `}
              onClick={e => {
                onSubmit(e, "Identity Proof");
                setUploaded("Identity Proof");
              }}
            >
              {uploaded === "Identity Proof" && isDocumentUploadLoading ? (
                <ButtonLoader />
              ) : disable["Identity Proof"] === false ? (
                <p id="Identity Proof">Uploaded</p>
              ) : (
                <p id="Identity Proof">Upload</p>
              )}
            </button>
          )}

          {previews["Identity Proof"]?.blob_file && (
            <a
              className={`col-4 upload_btn btn `}
              href={previews["Identity Proof"]?.blob_file}
              target="_blank"
            >
              Preview
            </a>
          )}

          {documents["Identity Proof"]?.upload_file && (
            <i
              className="fas fa-trash-alt trash-icon"
              onClick={() => {
                removeDocument("Identity Proof");
              }}
            />
          )}
        </div>
        {errors["Identity Proof"] && (
          <ErrorMsg rel>{errors["Identity Proof"]}</ErrorMsg>
        )}
      </UploaderBody>

      {/* ================================= Age ============================ */}
      <UploaderBody
        className="col-md-12 select_wrapper"
        active={documents["Age Proof"]?.upload_file ? true : false}
      >
        <div className="upload__body">
          <UploadWrap>
            <Selectt
              options={age_proof}
              label={"Age Proof"}
              selectedValue={documents["Age Proof"]?.file_type}
              noSearch
              readOnly
              handleChange={value =>
                setDocuments({
                  ...documents,
                  ["Age Proof"]: {
                    ...(documents["Age Proof"] ? documents["Age Proof"] : {}),
                    file_type: value,
                  },
                })
              }
            />
          </UploadWrap>
          <FileInputWrapper className="col-md-6">
            <span className="w-100 file_name_container">
              {docName.age_proof}
              <input
                type="file"
                name="myfile"
                id={docName.age_proof}
                onClick={e => {
                  e.target.value = null;
                }}
                onChange={e => {
                  if (
                    e.target.files[0] &&
                    (!checkSize(e, 5) || !checkDocType(e, ["pdf", "jpg"]))
                  ) {
                    setErrors({
                      ...errors,
                      ["Age Proof"]: "File type or size not allowed",
                    });
                  } else {
                    const name = `${appNo}_AgeProof_${currentDate}_3.${
                      e.target.files[0]?.name?.split(".")?.reverse()[0]
                    }`;
                    const doc_format = e.target.files[0]?.name
                      ?.split(".")
                      ?.reverse()[0];
                    setDocName(prev => ({
                      ...prev,
                      age_proof: name,
                    }));
                    setErrors({
                      ...errors,
                      ["Age Proof"]: "",
                    });
                    setDocuments({
                      ...documents,
                      ["Age Proof"]: {
                        ...(documents["Age Proof"]
                          ? documents["Age Proof"]
                          : {}),
                        upload_file: e.target.files[0],
                        upload_file_id: 3,
                        upload_file_name: name,
                        doc_format,
                      },
                    });

                    setPreviews({
                      ...previews,
                      ["Age Proof"]: {
                        ...(previews["Age Proof"] ? previews["Age Proof"] : {}),
                        blob_file: createPreview(e),
                      },
                    });
                  }
                }}
              />
            </span>
          </FileInputWrapper>

          {!documents["Age Proof"]?.upload_file ? (
            <label className="Choose_file_btn" htmlFor={docName.age_proof}>
              Choose a file
            </label>
          ) : (
            <button
              disabled={disable["Age Proof"] === false}
              className={`col-4 Choose_file_btn btn `}
              onClick={e => {
                onSubmit(e, "Age Proof");
                setUploaded("Age Proof");
              }}
            >
              {uploaded === "Age Proof" && isDocumentUploadLoading ? (
                <ButtonLoader />
              ) : disable["Age Proof"] === false ? (
                <p id="Age Proof">Uploaded</p>
              ) : (
                <p id="Age Proof">Upload</p>
              )}
            </button>
          )}

          {previews["Age Proof"]?.blob_file && (
            <a
              className={`col-4 upload_btn btn `}
              href={previews["Age Proof"]?.blob_file}
              target="_blank"
            >
              Preview
            </a>
          )}

          {documents["Age Proof"]?.upload_file && (
            <i
              className="fas fa-trash-alt trash-icon"
              onClick={() => {
                removeDocument("Age Proof");
              }}
            />
          )}
        </div>
        {errors["Age Proof"] && <ErrorMsg rel>{errors["Age Proof"]}</ErrorMsg>}
      </UploaderBody>

      {/* ================================= Address ============================ */}
      <UploaderBody
        className="col-md-12 select_wrapper"
        active={
          documents["Residential Address Proof"]?.upload_file ? true : false
        }
      >
        <div className="upload__body">
          <UploadWrap>
            <Selectt
              options={addressProofOptions}
              label={"Residential Address Proof"}
              selectedValue={documents["Residential Address Proof"]?.file_type}
              noSearch
              readOnly
              handleChange={value =>
                setDocuments({
                  ...documents,
                  ["Residential Address Proof"]: {
                    ...(documents["Residential Address Proof"]
                      ? documents["Residential Address Proof"]
                      : {}),
                    file_type: value,
                  },
                })
              }
            />
          </UploadWrap>
          <FileInputWrapper className="col-md-6">
            <span className="w-100 file_name_container">
              {docName.address_proof}
              <input
                type="file"
                name="myfile"
                id={docName.address_proof}
                onClick={e => {
                  e.target.value = null;
                }}
                onChange={e => {
                  if (
                    e.target.files[0] &&
                    (!checkSize(e, 5) || !checkDocType(e, ["pdf", "jpg"]))
                  ) {
                    setErrors({
                      ...errors,
                      ["Residential Address Proof"]:
                        "File type or size not allowed",
                    });
                  } else {
                    const name = `${appNo}_AddressProof_${currentDate}_4.${
                      e.target.files[0]?.name.split(".")?.reverse()[0]
                    }`;
                    const doc_format = e.target.files[0]?.name
                      ?.split(".")
                      ?.reverse()[0];
                    setDocName(prev => ({
                      ...prev,
                      address_proof: name,
                    }));

                    setErrors({
                      ...errors,
                      ["Residential Address Proof"]: "",
                    });
                    setDocuments({
                      ...documents,
                      ["Residential Address Proof"]: {
                        ...(documents["Residential Address Proof"]
                          ? documents["Residential Address Proof"]
                          : {}),
                        upload_file: e.target.files[0],
                        upload_file_id: 4,
                        upload_file_name: name,
                        doc_format,
                      },
                    });

                    setPreviews({
                      ...previews,
                      ["Residential Address Proof"]: {
                        ...(previews["Residential Address Proof"]
                          ? previews["Residential Address Proof"]
                          : {}),
                        blob_file: createPreview(e),
                      },
                    });
                  }
                }}
              />
            </span>
          </FileInputWrapper>

          {!documents["Residential Address Proof"]?.upload_file ? (
            <label className="Choose_file_btn" htmlFor={docName.address_proof}>
              Choose a file
            </label>
          ) : (
            <button
              disabled={disable["Residential Address Proof"] === false}
              className={`col-4 Choose_file_btn btn `}
              onClick={e => {
                onSubmit(e, "Residential Address Proof");
                setUploaded("Residential Address Proof");
              }}
            >
              {uploaded === "Residential Address Proof" &&
              isDocumentUploadLoading ? (
                <ButtonLoader />
              ) : disable["Residential Address Proof"] === false ? (
                <p id="Residential Address Proof">Uploaded</p>
              ) : (
                <p id="Residential Address Proof">Upload</p>
              )}
            </button>
          )}

          {previews["Residential Address Proof"]?.blob_file && (
            <a
              className={`col-4 upload_btn btn `}
              href={previews["Residential Address Proof"]?.blob_file}
              target="_blank"
            >
              Preview
            </a>
          )}

          {documents["Residential Address Proof"]?.upload_file && (
            <i
              className="fas fa-trash-alt trash-icon"
              onClick={() => removeDocument("Residential Address Proof")}
            />
          )}
        </div>
        {errors["Residential Address Proof"] && (
          <ErrorMsg rel>{errors["Residential Address Proof"]}</ErrorMsg>
        )}
      </UploaderBody>

      {/* ================================= Income ============================ */}
      <UploaderBody
        className="col-md-12 select_wrapper"
        active={documents["Income Proof"]?.upload_file ? true : false}
      >
        <div className="upload__body">
          <UploadWrap>
            <Selectt
              options={incomeProofOptions}
              label={"Income Proof"}
              selectedValue={documents["Income Proof"]?.file_type}
              noSearch
              readOnly
              handleChange={value =>
                setDocuments({
                  ...documents,
                  ["Income Proof"]: {
                    ...(documents["Income Proof"]
                      ? documents["Income Proof"]
                      : {}),
                    file_type: value,
                  },
                })
              }
            />
          </UploadWrap>

          <FileInputWrapper className="col-md-6">
            <span className="w-100 file_name_container">
              {docName.income_proof}
              <input
                type="file"
                name="myfile"
                id={docName.income_proof}
                onClick={e => {
                  e.target.value = null;
                }}
                onChange={e => {
                  if (
                    e.target.files[0] &&
                    (!checkSize(e, 5) || !checkDocType(e, ["pdf", "jpg"]))
                  ) {
                    setErrors({
                      ...errors,
                      ["Income Proof"]: "File type or size not allowed",
                    });
                  } else {
                    const name = `${appNo}_IncomeProof_${currentDate}_5.${
                      e.target.files[0]?.name?.split(".")?.reverse()[0]
                    }`;
                    const doc_format = e.target.files[0]?.name
                      ?.split(".")
                      ?.reverse()[0];
                    setDocName(prev => ({
                      ...prev,
                      income_proof: name,
                    }));
                    setErrors({
                      ...errors,
                      ["Income Proof"]: "",
                    });
                    setDocuments({
                      ...documents,
                      ["Income Proof"]: {
                        ...(documents["Income Proof"]
                          ? documents["Income Proof"]
                          : {}),
                        upload_file: e.target.files[0],
                        upload_file_id: 5,
                        upload_file_name: name,
                        doc_format,
                      },
                    });

                    setPreviews({
                      ...previews,
                      ["Income Proof"]: {
                        ...(previews["Income Proof"]
                          ? previews["Income Proof"]
                          : {}),
                        blob_file: createPreview(e),
                      },
                    });
                  }
                }}
              />
            </span>
          </FileInputWrapper>

          {!documents["Income Proof"]?.upload_file ? (
            <label className="Choose_file_btn" htmlFor={docName.income_proof}>
              Choose a file
            </label>
          ) : (
            <button
              disabled={disable["Income Proof"] === false}
              className={`col-4 Choose_file_btn btn `}
              onClick={e => {
                onSubmit(e, "Income Proof");
                setUploaded("Income Proof");
              }}
            >
              {uploaded === "Income Proof" && isDocumentUploadLoading ? (
                <ButtonLoader />
              ) : disable["Income Proof"] === false ? (
                <p id="Income Proof">Uploaded</p>
              ) : (
                <p id="Income Proof">Upload</p>
              )}
            </button>
          )}

          {previews["Income Proof"]?.blob_file && (
            <a
              className={`col-4 upload_btn btn `}
              href={previews["Income Proof"]?.blob_file}
              target="_blank"
            >
              Preview
            </a>
          )}

          {documents["Income Proof"]?.upload_file && (
            <i
              className="fas fa-trash-alt trash-icon"
              onClick={() => removeDocument("Income Proof")}
            />
          )}
        </div>
        {errors["Income Proof"] && (
          <ErrorMsg rel>{errors["Income Proof"]}</ErrorMsg>
        )}
      </UploaderBody>
      {/* ======================= Bank Proof =====================*/}
      <UploaderBody
        className="col-md-12 select_wrapper"
        active={documents["Bank Proof"]?.upload_file ? true : false}
      >
        <div className="upload__body">
          <UploadWrap>
            <Selectt
              readOnly
              options={bankProofOptions}
              label={"Bank Proof"}
              selectedValue={documents["Bank Proof"]?.file_type}
              handleChange={value =>
                setDocuments({
                  ...documents,
                  ["Bank Proof"]: {
                    ...(documents["Bank Proof"] ? documents["Bank Proof"] : {}),
                    file_type: value,
                  },
                })
              }
            />
          </UploadWrap>

          <FileInputWrapper className="col-md-6">
            <span className="w-100 file_name_container">
              {docName.bank_proof}
              <input
                type="file"
                name="myfile"
                id={docName.bank_proof}
                onClick={e => {
                  e.target.value = null;
                }}
                onChange={e => {
                  if (
                    e.target.files[0] &&
                    (!checkSize(e, 5) || !checkDocType(e, ["pdf", "jpg"]))
                  ) {
                    setErrors({
                      ...errors,
                      ["Bank Proof"]: "File type or size not allowed",
                    });
                  } else {
                    const name = `${appNo}_BankProof_${currentDate}_8.${
                      e.target.files[0]?.name?.split(".")?.reverse()[0]
                    }`;
                    const doc_format = e.target.files[0]?.name
                      ?.split(".")
                      ?.reverse()[0];
                    setDocName(prev => ({
                      ...prev,
                      bank_proof: name,
                    }));
                    setErrors({
                      ...errors,
                      ["Bank Proof"]: "",
                    });
                    setDocuments({
                      ...documents,
                      ["Bank Proof"]: {
                        ...(documents["Bank Proof"]
                          ? documents["Bank Proof"]
                          : {}),
                        upload_file: e.target.files[0],
                        upload_file_id: 8,
                        upload_file_name: name,
                        doc_id: documents["Bank Proof"]?.file_type,
                        doc_type: "FORM",
                        doc_format,
                      },
                    });

                    setPreviews({
                      ...previews,
                      ["Bank Proof"]: {
                        ...(previews["Bank Proof"]
                          ? previews["Bank Proof"]
                          : {}),
                        blob_file: createPreview(e),
                      },
                    });
                  }
                }}
              />
            </span>
          </FileInputWrapper>

          {!documents["Bank Proof"]?.upload_file ? (
            <label className="Choose_file_btn" htmlFor={docName?.bank_proof}>
              Choose a file
            </label>
          ) : (
            <button
              disabled={disable["Bank Proof"] === false}
              className={`col-4 Choose_file_btn btn `}
              onClick={e => {
                onSubmit(e, "Bank Proof");
                setUploaded("Bank Proof");
              }}
            >
              {uploaded === "Bank Proof" && isDocumentUploadLoading ? (
                <ButtonLoader />
              ) : disable["Bank Proof"] === false ? (
                <p id="Bank Proof">Uploaded</p>
              ) : (
                <p id="Bank Proof">Upload</p>
              )}
            </button>
          )}

          {previews["Bank Proof"]?.blob_file && (
            <a
              className={`col-4 upload_btn btn `}
              href={previews["Bank Proof"]?.blob_file}
              target="_blank"
            >
              Preview
            </a>
          )}

          {documents["Bank Proof"]?.upload_file && (
            <i
              className="fas fa-trash-alt trash-icon"
              onClick={() => removeDocument("Bank Proof")}
            />
          )}
        </div>
        {errors["Bank Proof"] && (
          <ErrorMsg rel>{errors["Bank Proof"]}</ErrorMsg>
        )}
      </UploaderBody>

      {/* ================================= Other Documents ============================ */}
      <UploaderBody
        className="col-md-12 select_wrapper"
        active={documents["Other Document"]?.upload_file ? true : false}
      >
        <div className="upload__body">
          <UploadWrap>
            {" "}
            <Title label={"Any Other Documents"} />
          </UploadWrap>
          <FileInputWrapper className="col-md-6">
            <span className="w-100 file_name_container">
              {docName.other_doc}
              <input
                type="file"
                name="myfile"
                id={docName.other_doc}
                onClick={e => {
                  e.target.value = null;
                }}
                onChange={e => {
                  if (
                    e.target.files[0] &&
                    (!checkSize(e, 5) || !checkDocType(e, ["pdf", "jpg"]))
                  ) {
                    setErrors({
                      ...errors,
                      ["Other Document"]: "File type or size not allowed",
                    });
                  } else {
                    const name = `${appNo}_OtherDocument_${currentDate}_7.${
                      e.target.files[0]?.name?.split(".")?.reverse()[0]
                    }`;
                    const doc_format = e.target.files[0]?.name
                      ?.split(".")
                      ?.reverse()[0];

                    setDocName(prev => ({
                      ...prev,
                      other_doc: name,
                    }));
                    setErrors({
                      ...errors,
                      ["Other Document"]: "",
                    });
                    setDocuments({
                      ...documents,
                      ["Other Document"]: {
                        ...(documents["Other Document"]
                          ? documents["Other Document"]
                          : {}),
                        upload_file: e.target.files[0],
                        upload_file_id: 7,
                        upload_file_name: name,
                        doc_id: "1021010193",
                        doc_type: "FORM",
                        doc_format,
                      },
                    });

                    setPreviews({
                      ...previews,
                      ["Other Document"]: {
                        ...(previews["Other Document"]
                          ? previews["Other Document"]
                          : {}),
                        blob_file: createPreview(e),
                      },
                    });
                  }
                }}
              />
            </span>
          </FileInputWrapper>

          {!documents["Other Document"]?.upload_file ? (
            <label className="Choose_file_btn" htmlFor={docName.other_doc}>
              Choose a file
            </label>
          ) : (
            <button
              disabled={disable["Other Document"] === false}
              className={`col-4 Choose_file_btn btn `}
              onClick={e => {
                onSubmit(e, "Other Document");
                setUploaded("Other Document");
              }}
            >
              {uploaded === "Other Document" && isDocumentUploadLoading ? (
                <ButtonLoader />
              ) : disable["Other Document"] === false ? (
                <p id="Other Document">Uploaded</p>
              ) : (
                <p id="Other Document">Upload</p>
              )}
            </button>
          )}

          {previews["Other Document"]?.blob_file && (
            <a
              className={`col-4 upload_btn btn `}
              href={previews["Other Document"]?.blob_file}
              target="_blank"
            >
              Preview
            </a>
          )}

          {documents["Other Document"]?.upload_file && (
            <i
              className="fas fa-trash-alt trash-icon"
              onClick={() => removeDocument("Other Document")}
            />
          )}
        </div>
        {errors["Other Document"] && (
          <ErrorMsg rel>{errors["Other Document"]}</ErrorMsg>
        )}
      </UploaderBody>
    </div>
  );
};

export default TataDocumentUpload;
