import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import React, { useState } from "react";
import { styled } from "@material-ui/core";

function DifferencesButton({ setCurrentSection }) {
  const [a, setA] = useState("1");
  return (
    <CustomGroup
      color='primary'
      value={a}
      exclusive
      onChange={(e) => setA(e.target.value)}
    >
      <CustomButton value={`1`} onClick={() => setCurrentSection(1)}>
        Fixed Deposits vs Guranteed Returns Plan
      </CustomButton>
      <CustomButton value={`2`} onClick={() => setCurrentSection(2)}>
        Returns Calculator
      </CustomButton>
    </CustomGroup>
  );
}
const CustomGroup = styled(ToggleButtonGroup)({
  "&.MuiToggleButtonGroup-root": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    // width: "75%",
    width: "55rem",
    margin: "auto",
  },
});

const CustomButton = styled(ToggleButton)({
  "&.MuiButtonBase-root,MuiToggleButton-root": {
    width: "48%",
    padding: "15px",
    fontSize: "16px",
    fontFamily: "Inter",
    fontWeight: "600",
    textTransform: "capitalize",
  },
});

export default DifferencesButton;
