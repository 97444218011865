import React, { useState } from "react";
import AutoComplete from "@mui/lab/Autocomplete";
import { useEffect } from "react";
// import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { styled } from "@material-ui/styles";
import TextInput from "../../QuotesPage/components/EditDetailsSection/components/TextInput";
import { createFilterOptions, Popper } from "@mui/material";
import { useSelector } from "react-redux";
// import SpinnerLoader from "../../../../../components/CardSkeletonLoader/SpinnerLoader/SpinnerLoader";
const Select = ({
  options = [{ code: "", display_name: "" }],
  handleChange = () => {},
  selectedValue,
  label,
  error,
  readOnly,
  dropdownHeight,
  annualIncome,
  pinCodeFetch,
  ...props
}) => {
  const [value, setValue] = useState(null);
  //   const { isAddressFetching } = useSelector((state) => state.eligibility);
  const [inputValue, setInputValue] = useState("");
  const onChange = (e, value) => {
    setValue(value);
    handleChange(value.code);
  };
  useEffect(() => {
    if (selectedValue) {
      const x = options.find(item => item.code === selectedValue);
      setValue(x || null);
      setInputValue(x?.display_name || "");
    } else {
      setValue(null);
      setInputValue("");
    }
  }, [selectedValue]);
  const onInputChange = (e, newValue) => {
    setInputValue(newValue);
  };
  const filterOptions = createFilterOptions({
    matchFrom: "start",
    stringify: option =>
      annualIncome ? option.code.toString() : option.display_name,
  });
  return (
    <>
      {" "}
      {readOnly ? (
        <TextInput
          className="w-100"
          value={inputValue}
          label={label}
          inputProps={{ readOnly: true }}
        />
      ) : (
        <StyledAutoComplete
          className="w-100"
          filterOptions={filterOptions || ""}
          options={options}
          disablePortal
          disableClearable
          blurOnSelect
          onChange={onChange}
          onInputChange={onInputChange}
          getOptionLabel={item => item.display_name || ""}
          value={value}
          clearOnBlur
          //   popupIcon={
          //     pinCodeFetch && isAddressFetching ? (
          //       <SpinnerLoader />
          //     ) : (
          //       <ArrowDropDownIcon />
          //     )
          //   }
          inputValue={inputValue || ""}
          PopperComponent={({ style, ...props }) => (
            <Popper {...props} style={{ ...style, height: 0 }} />
          )}
          ListboxProps={{
            style: {
              maxHeight: dropdownHeight ? dropdownHeight : "50vh",
            },
          }}
          isOptionEqualToValue={(item, value) => item.code === value.code}
          renderInput={params => (
            <TextInput
              {...params}
              label={label}
              error={error}
              inputProps={{
                ...params.inputProps,
                autoComplete: props?.autoComplete,
                readOnly: props?.noSearch,
              }}
            />
          )}
        />
      )}
    </>
  );
};
const StyledAutoComplete = styled(AutoComplete)({
  marginBottom: "0.95rem",
  "& .MuiOutlinedInput-root": {
    padding: "0px 0 0 14px !important",
    color: "#000 !important",
    minHeight: "65px",
    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      display: "none",
    },
  },
  "& .MuiOutlinedInput-input": {
    padding: "4px !important",
  },
});
export default Select;
