import Landing from "./pages/Landing/Landing";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { useEffect } from "react";
import styled from "styled-components/macro";
import ComparePage from "./pages/ComparePage/ComparePage";
import "./App.scss";
import { getData } from "./pages/Landing/input.slice";

import { useDispatch } from "react-redux";
import { DifferencesNav } from "./pages/QuotesPage/components/MoreQuestions/DifferencesNav";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useUrlQuery from "./custom-hooks/useUrlQuery";
import { useGetFrontendBootQuery } from "./pages/rider/services/rider";
import { ShortListedPlansPage } from "./pages/QuotesPage/components/ShortListQuotes/ShortListedPlansPage";
import FullScreenLoader from "./components/Loaders/FullScreenLoader/FullScreenLoader";
import useUpdateFilterWithEnquiry from "./custom-hooks/useUpdateFilterWithEnquiry";
import React from "react";
import { Suspense } from "react";
import { ErrorBoundary } from "react-error-boundary";
import ErrorBoundaryComp from "./ErrorBoundaryComp";
import ThankyouPage from "./pages/ThankYouPage/ThankyouPage";
import PaymentStatusPage from "./pages/PaymentStatus/PaymentStatusPage";
import DocumentUploadPage from "./pages/DocumentUpload/DocumentUploadPage";
import InvestInfo from "./pages/QuotesPage/components/InvestInfo";
import RiderInfo from "./pages/QuotesPage/components/RiderInfo";
import RiderPageNew from "./pages/RiderPageNew/RiderPageNew";
const QuotesPage = React.lazy(() => import("./pages/QuotesPage/QuotesPage"));
const ProposalPage = React.lazy(() =>
  import("./pages/ProposalPage/ProposalPage"),
);
function App() {
  const dispatch = useDispatch();
  const { isLoading } = useGetFrontendBootQuery();

  // useEffect(() => {
  //   if (error) {
  //     console.log(error);
  //   }
  //   else {
  //     dispatch(setFrontendData({ data, isLoading }))
  //   }
  // }, [data, isLoading]);

  const enquiryId = useUrlQuery("enquiryId");

  useEffect(() => {
    if (enquiryId) dispatch(getData());
  }, []);
  useUpdateFilterWithEnquiry();

  if (isLoading) return <Loader />;

  return (
    <div className="app">
      <BrowserRouter>
        {/* <Landing/> */}
        <ErrorBoundary
          FallbackComponent={ErrorBoundaryComp}
          onReset={() => {
            window.history.back();
          }}
        >
          <Routes>
            <Route exact path="/" element={<Landing />}></Route>
            <Route
              exact
              path="/quote"
              element={
                <Suspense fallback={<Loader />}>
                  <QuotesPage />
                </Suspense>
              }
            ></Route>
            {/* <Route
              exact
              path="/rider"
              element={
                <Suspense fallback={<Loader />}>
                  <RiderPage />
                </Suspense>
              }
            ></Route> */}
            <Route
              exact
              path="/riders"
              element={
                <Suspense fallback={<Loader />}>
                  <RiderPageNew />
                </Suspense>
              }
            ></Route>

            <Route
              exact
              path="/investment/proposal"
              element={
                <Suspense fallback={<Loader />}>
                  <ProposalPage />
                </Suspense>
              }
            ></Route>
            <Route
              exact
              path="/investment/thankyou"
              element={
                <Suspense fallback={<Loader />}>
                  <ThankyouPage />
                </Suspense>
              }
            ></Route>
            <Route
              exact
              path="/success"
              element={
                <Suspense fallback={<Loader />}>
                  <PaymentStatusPage />
                </Suspense>
              }
            ></Route>
            <Route
              exact
              path="/document_upload"
              element={
                <Suspense fallback={<Loader />}>
                  <DocumentUploadPage />
                </Suspense>
              }
            ></Route>
            <Route
              exact
              path="/differences"
              element={<DifferencesNav />}
            ></Route>
            <Route exact path="/compare" element={<ComparePage />}></Route>
            <Route
              exact
              path="/shortlisted-plans"
              element={<ShortListedPlansPage />}
            ></Route>
            <Route
              path="/investment-information"
              element={<InvestInfo />}
            ></Route>
            <Route path="/rider-information" element={<RiderInfo />}></Route>
          </Routes>
        </ErrorBoundary>
      </BrowserRouter>
      <ToastContainer />
    </div>
  );
}

export default App;

const LoaderContainer = styled.div`
  width: 100%;
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
`;
const Loader = () => {
  return (
    <>
      <LoaderContainer>
        <FullScreenLoader />
      </LoaderContainer>
    </>
  );
};
