import React, { useState } from "react";
import "styled-components/macro";
import { FormCard, FormWrapper } from "../../Landing.style";
import Premium from "./forms/Premium";
import * as mq from "../../../../styles/mediaQueries";
import Proffession from "./forms/Proffession";
import Question from "./forms/Question";
import WhatsappUpdate from "./WhatsappUpdate";
import parse from "html-react-parser";
import { useSelector, useDispatch } from "react-redux";
import { Checkbox } from "@mui/material";
import { setData } from "../../input.slice";
import Eligibility from "./forms/Eligibility";

const LandingForm = () => {
  const { investmentinputpage } = useSelector(
    state => state.frontendBoot?.frontendData,
  );
  console.log(investmentinputpage);
  const [current, setCurrent] = useState(1);
  const prevNum = React.useRef();
  const [checked, setChecked] = useState(true);
  const dispatch = useDispatch();
  React.useEffect(() => {
    prevNum.current = current;
  }, [current]);

  if (!prevNum.current) {
    prevNum.current = 1;
  }

  return (
    <>
      <div
        css={`
          width: 50%;

          ${mq.sm} {
            display: block;
            width: 100%;
          }
          ${mq.md} {
            display: block;
            width: 100%;
            margin: 0 auto;
          }
        `}
      >
        <FormCard>
          <FormWrapper>
            {current === 1 && <Premium setCurrent={setCurrent} />}
            {current === 2 && <Eligibility setCurrent={setCurrent} />}
            {current === 3 && <Question setCurrent={setCurrent} />}
            {current === 4 && <Proffession setCurrent={setCurrent} />}
          </FormWrapper>
        </FormCard>
        {/* {investmentinputpage?.investmentsuitabilityflagstatus &&
        current === 3 &&
        investmentinputpage?.investmentsuitabilityflagstatus === "Yes" ? (
          <div
            css={`
              display: flex;
              width: 77%;
              justify-content: flex-start;
              margin-left: 68px;
              padding: 12px;
              ${mq.sm} {
                width: 100%;
                margin-left: 11px;
              }
              ${mq.md} {
                width: 100%;
                justify-content: flex-start;
                margin-left: 0px;
              }
              @media screen and (min-width: 820px) and (max-width: 884px) {
                width: 92%;
              }
              @media screen and (min-width: 1440px) and (max-width: 1520px) {
                width: 74%;
                margin-left: 87px;
              }
            `}
          >
            <Checkbox
              size="small"
              name={"suitability_check"}
              css={`
                color: var(--primary-color);
                padding: 0px;
                top: -36px;
                ${mq.md} {
                  top: -55px;
                }
                @media screen and (min-width: 1440px) and (max-width: 1520px) {
                  top: -35px;
                }
              `}
              // defaultChecked={checked}
              onChange={e =>
                dispatch(
                  setData({
                    suitability: { suitability_check: e.target.checked },
                  }),
                )
              }
            />

            {investmentinputpage?.investmentsuitabilitydisclaimer &&
              current === 3 && (
                <span>
                  {parse(investmentinputpage?.investmentsuitabilitydisclaimer)}
                </span>
              )}
          </div>
        ) : (
          <></>
        )} */}
        {investmentinputpage?.investmentsuitabilityflagstatus &&
        current === 3 &&
        investmentinputpage?.investmentsuitabilityflagstatus === "Yes" ? (
          <>
            <div
              css={`
                display: flex;
                justify-content: center;
                width: 74%;
                margin: 0 auto;
                margin-top: 10px;
                ${mq.sm} {
                  width: 92%;
                }
                ${mq.md} {
                  width: 91%;
                }
                @media screen and (min-width: 1440px) and (max-width: 1650px) {
                  width: 72%;
                }
              `}
            >
              <div>
                <Checkbox
                  size="small"
                  name={"suitability_check"}
                  css={`
                    top: -7px;
                    left: -8px;
                    @media screen and (min-width: 1440px) and (max-width: 1650px) {
                      left: 0px;
                    }
                  `}
                  // defaultChecked={checked}
                  onChange={e =>
                    dispatch(
                      setData({
                        suitability: { suitability_check: e.target.checked },
                      }),
                    )
                  }
                />
              </div>
              <div>
                {investmentinputpage?.investmentsuitabilitydisclaimer &&
                  current === 3 && (
                    <span>
                      {parse(
                        investmentinputpage?.investmentsuitabilitydisclaimer,
                      )}
                    </span>
                  )}
              </div>
            </div>
          </>
        ) : (
          <></>
        )}
        {investmentinputpage?.investmentwhatsappflagstatus &&
        current === 4 &&
        investmentinputpage?.investmentwhatsappflagstatus === "Yes" ? (
          <div
            // style={{
            //   display: "flex",
            //   justifyContent: "center",
            //   alignItems: "center",
            //   marginRight: "20em",
            // }}
            css={`
            display:"flex",
            };
            // margin-top: 10px;
            ${mq.md} {
              width: 90%;
              // margin: 20px auto;
            }
            ${mq.sm} {
              width: 90%;
              // margin: 20px auto;
            }
            @media screen and (max-width:767px){
              margin:0px 0px 0px -10px;
            }
          `}
          >
            <WhatsappUpdate />
          </div>
        ) : (
          <></>
        )}
        {investmentinputpage?.investmentdisclaimer && current === 4 && (
          <div
            css={`
              margin: 0px auto;
              width: 500px;
              ${mq.md} {
                width: 90%;
                margin: 0px auto;
              }
              ${mq.sm} {
                width: 90%;
                margin: 0px auto;
              }
            `}
          >
            {parse(investmentinputpage.investmentdisclaimer)}
          </div>
        )}

        {/* <div
          css={`
            display: ${current === 3 ? "flex" : "none"};
            // justify-content: flex-start;
            margin-left: 10em;
            ${mq.sm} {
              margin-left: 10px;
            }
          `}
        >
          <WhatsappUpdate />
        </div> */}

        {/* <div
          css={`
            display: ${current === 3 ? "flex" : "none"};
            margin-right: 100px;
            // margin-top: 10px;
            ${mq.sm} {
              width: 90%;
              margin: 20px auto;
            }
            ${mq.md} {
              width: 90%;
              margin: 20px auto;
            }
          `}
        >
          <label
            css={`
              font-size: 13px;
              margin: 1.5em auto;
              position: relative;
              bottom: 10px;
              ${mq.sm} {
                position: relative;
                bottom: 25px;
              }
            `}
          >
            By clicking on “View Quotes”, you agree to our{" "}
            <a href="/">Privacy Policy</a> and{" "}
            <br
              css={`
                ${mq.sm} {
                  display: none;
                }
              `}
            />
            <a href="/">Terms & Conditions</a>
          </label>
        </div> */}
      </div>
    </>
  );
};

export default LandingForm;

{
  /* <div
  css={`
    width: 50%;
    ${mq.sm} {
      display: block;
      width: 100%;
      overflow-x: hidden;
    }
    ${mq.md} {
      display: block;
      width: 100%;
      margin: 0 auto;
      overflow-x: hidden;
    }
  `}
  
>
  <FormCard>
    <FormWrapper>
      {current === 1 && <Premium setCurrent={setCurrent} />}
      {current === 2 && <Proffession setCurrent={setCurrent} />} 
    {current > prevNum.current ? (
      <div
        css={`
          animation-duration: 0.3s;
          animation-name: "slideRight";

          @keyframes slideLeft {
            0% {
              transform: translateX(-300px);
            }

            100% {
              transform: translate(0);
            }
          }

          @keyframes slideRight {
            0% {
              transform: translateX(300px);
            }

            100% {
              transform: translate(0);
            }
          }
        `}
      >
        <Proffession setCurrent={setCurrent} />
      </div>
    ) : (
      <div
        css={`
          animation-duration: 0.3s;
          animation-name: "slideLeft";

          @keyframes slideLeft {
            0% {
              transform: translateX(-300px);
            }

            100% {
              transform: translate(0);
            }
          }

          @keyframes slideRight {
            0% {
              transform: translateX(300px);
            }

            100% {
              transform: translate(0);
            }
          }
        `}
      >
        <Premium setCurrent={setCurrent} />
      </div>
    )} 
    </FormWrapper>
  </FormCard>
</div>; */
}
