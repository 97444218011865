import React, { memo, useState } from "react";
import { styled } from "@material-ui/core";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import TextInput from "./TextInput";
import { useEffect } from "react";
import moment from "moment";
//import { AgeWrapper } from '../ProposalPage.style';

const DateComponent = ({
  label,
  value,
  error,
  handleChange,
  maxDate,
  minDate,
  readOnly,
  agewrapper,
  ...props
}) => {
  const [selectedDate, setSelectedDate] = useState(null);
  const [age, setAge] = useState(null);

  // useEffect(() => {
  //   setSelectedDate(value || null);
  // }, [value]);

  useEffect(() => {
    setSelectedDate(moment(value, "DD/MM/YYYY"));
  }, [value]);

  useEffect(() => {
    setAge(moment().diff(selectedDate, "years"));
  }, [selectedDate]);

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <CustomDateField
        readOnly={readOnly}
        // disableOpenPicker
        allowSameDateSelection
        maxDate={maxDate}
        minDate={minDate || moment("01/01/1900")}
        inputProps={{ placeholder: "DD/MM/YYYY" }}
        value={selectedDate}
        views={["year", "month", "day"]}
        openTo="year"
        onChange={newDate => {
          setSelectedDate(newDate);
          handleChange(newDate?.format("DD/MM/YYYY"));
        }}
        onAccept={newDate => {
          setSelectedDate(newDate);
          handleChange(newDate?.format("DD/MM/YYYY"));
        }}
        inputFormat="DD/MM/YYYY"
        renderInput={params => (
          <>
            <TextInput
              {...params}
              label={label}
              error={error}
              age={agewrapper ? age : 0}
            />
          </>
        )}
        {...props}
      />
    </LocalizationProvider>
  );
};
export default memo(DateComponent);
const CustomDateField = styled(DatePicker)({});
