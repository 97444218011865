import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import * as mq from "../../../../styles/mediaQueries";

import BookmarkQuote from "../BookMarkQuote";
import { useDispatch, useSelector } from "react-redux";
import { selectICLogo } from "../../../frontendBoot/frontendBoot.slice";
import GetDetails from "../Details/GetDetails";
import useGetAmount from "../../../../custom-hooks/useGetAmount";
import useGetInWords from "../../../../custom-hooks/useGetInWords";
import {
  fetchKnowMoreData,
  selectShortListedPlanData,
  selectShortListedPlanTemp,
  setAddShowMoreQuotes,
  setRemoveShowMore,
  setSelectedQuote,
  setShortListedPlan,
  setShortListedPlansData,
} from "../../Quotes.slice";

import { updateEnquiries } from "../../../Landing/input.slice";
import { DeathBenefitSection } from "../../QuoteCard";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";

function QuotesCardMobile({
  quoteData,
  onBuyNowClick,
  showAddOn,
  setShowAddOn = () => {},
  differentQuote = false,
  showMorePlan,
  setShowMorePlan,
  hasMorePlans,
  morePlanCount,
  policyTermToShow,
  setPolicyTermToShow,
}) {
  const dispatch = useDispatch();

  const [showDetails, setShowDetails] = useState(false);

  const logo = useSelector(selectICLogo(quoteData?.company_alias));

  const temp = useSelector(selectShortListedPlanTemp);
  const shortlisted = useSelector(selectShortListedPlanData);

  useEffect(() => {
    if (
      temp.some(item => item?.product.id === quoteData?.product.id) &&
      shortlisted.some(item => item?.product.id === quoteData?.product.id) ===
        false
    ) {
      const { id } = quoteData?.product;
      dispatch(setShortListedPlan(id));
      dispatch(setShortListedPlansData(quoteData));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleGetDetails = () => {
    dispatch(setSelectedQuote(quoteData));
    dispatch(
      updateEnquiries({
        input: {
          selected_product: selectedProduct,
          policy_term: policyTermToShow,
        },
      }),
    );
    dispatch(fetchKnowMoreData(quoteData?.product?.id));
    onBuyNowClick && onBuyNowClick();
  };

  //product details
  const product = quoteData?.product_name ? quoteData?.product_name : "Product";
  const [planName, ...planType] = product
    ?.split("-")
    ?.map(item => item && item.trim());
  const benefit = quoteData?.maturity_benefit;
  const benefitWord = useGetInWords(benefit);
  const getMoneyAs = useSelector(state => state.filters.getMoneyAs);
  const investForValue = useSelector(state => state.filters.investFor) || "10";
  const returnsAfterValue = quoteData?.guaranteed_lumpsum_at || "20";
  const incomeForValue = quoteData?.income_for || "20";
  const incomeStartAtValue = `${quoteData?.income_start_at} years` || "0";
  const totalAmount = useGetAmount();
  const totalAmountWords = useGetInWords(totalAmount);

  const incomeBenefitInWords = useGetInWords(quoteData?.income_benfit);
  const income_benefit = quoteData?.income_benfit;

  const deathBenefit = useGetInWords(quoteData?.death_benefit);
  const productID = quoteData?.product?.id;
  const InsuranceID = quoteData?.investment_insurance_id;
  // eslint-disable-next-line no-unused-vars
  const [selectedProduct, setSelectedProduct] = useState({
    investment_insurance_id: InsuranceID,
    product_id: productID,
  });
  const totalAmountToBeReceived =
    quoteData?.income_benfit * incomeForValue +
    benefit +
    quoteData?.additional_lumpsum +
    quoteData?.additional_rop;

  const totalAmountInvested = quoteData.premium * investForValue;
  const returns =
    Math.round((totalAmountToBeReceived / totalAmountInvested) * 100) / 100;

  //

  const { showMoreQuotes } = useSelector(state => state.quotes);
  const handleShowMore = () => {
    if (showMoreQuotes?.includes(quoteData?.company_alias)) {
      setShowMorePlan(false);
      dispatch(setRemoveShowMore(quoteData?.company_alias));
    } else {
      setShowMorePlan(true);
      dispatch(setAddShowMoreQuotes(quoteData?.company_alias));
    }
  };
  return (
    <>
      <QuoteCardContainer>
        <DeathBenefitSection>
          Death Benefit : {deathBenefit}
        </DeathBenefitSection>
        <QuoteData>
          <QuoteCardMobileBookmark>
            <BookmarkQuote
              investmentId={quoteData?.product?.id}
              plans={quoteData}
            />
          </QuoteCardMobileBookmark>
          <QuoteDataMain hasMorePlans={hasMorePlans}>
            <div
              style={{
                width: "100px",
              }}
            >
              <QuoteDataIcon src={logo} />
            </div>
            <div
              style={{
                marginLeft: "10px",
              }}
            >
              <h1
                css={`
                  font-family: Inter;
                  font-weight: 600;
                  font-size: 14px;
                  margin-bottom: 0px;
                  ${mq.sm} {
                    font-size: 12px;
                    font-family: Inter;
                    font-weight: 600;
                  }
                  ${mq.xs} {
                    font-size: 11px;
                  }
                `}
              >
                {planName}
              </h1>
              <h2
                css={`
                  font-family: "Inter";
                  font-size: 10px;
                  margin-bottom: 0px;
                  ${mq.sm} {
                    font-size: 9.5px;
                    font-weight: 400;
                  }
                `}
              >
                {planType.join("-")}
              </h2>
            </div>
          </QuoteDataMain>
          {hasMorePlans && (
            <ShowMorePlanDiv
              css={`
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 10px;
                border-radius: 5px 5px 0 0;
                font-weight: 700;
                padding: 2px 5px;
                color: #000000;
                cursor: pointer;
                text-align: center;
                text-decoration: underline;
                width: 30%;
              `}
              onClick={handleShowMore}
            >
              {showMorePlan ? (
                <FaAngleUp
                  style={{
                    fontSize: "13px",
                  }}
                />
              ) : (
                <FaAngleDown
                  style={{
                    fontSize: "13px",
                  }}
                />
              )}
              {showMorePlan ? "Close" : `${morePlanCount} More Plan`}
            </ShowMorePlanDiv>
          )}
          {/*  */}
        </QuoteData>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            marginBottom: "10px",
            // justifyContent: "space-around",
          }}
        >
          <QuoteDataSub>
            <QuoteDataTextMedium
              style={{
                marginBottom: "0px",
              }}
            >
              You Invest
            </QuoteDataTextMedium>
            <QuoteDataNumericValues>
              {" "}
              {totalAmountWords}{" "}
              <span
                style={{
                  fontSize: "10px",
                  fontWeight: "400",
                }}
              >
                / Year
              </span>
            </QuoteDataNumericValues>
            <QuoteDataTextMedium
              style={{
                marginBottom: "0px",
              }}
            >
              For {investForValue} Years
            </QuoteDataTextMedium>
          </QuoteDataSub>
          <QuoteDataSub>
            <QuoteDataTextMedium
              css={`
                margin: 0px 0px 0px 0px;
              `}
            >
              You Recieve
            </QuoteDataTextMedium>
            <QuoteDataNumericValues>
              {income_benefit ? incomeBenefitInWords : benefitWord}
            </QuoteDataNumericValues>

            <QuoteDataTextMedium
              css={`
                margin: 0px 0px 0px 0px;
                color: ${quoteData?.differentQuote && "red"};
              `}
            >
              {getMoneyAs === "I" || getMoneyAs === "B" ? "/Year" : "After"}{" "}
              {getMoneyAs === "I" || getMoneyAs === "B" ? (
                `For ${incomeForValue} Years`
              ) : (
                <span>{returnsAfterValue} Years</span>
              )}
            </QuoteDataTextMedium>
          </QuoteDataSub>
          <QuoteDataSub last>
            <QuoteDataTextMedium
              css={`
                margin: 0px 0px 0px 0px;
              `}
            >
              Returns
            </QuoteDataTextMedium>
            {getMoneyAs === "L" ? (
              <QuoteDataNumericValues amount>
                IRR {quoteData?.irr_value}%{" "}
              </QuoteDataNumericValues>
            ) : (
              <QuoteDataNumericValues amount>
                {returns} times
              </QuoteDataNumericValues>
            )}

            {getMoneyAs === "L" ? (
              <>
                <QuoteDataTextMedium
                  css={`
                    margin: 0px 0px 0px 0px;
                    color: ${quoteData?.differentQuote && "red"};
                  `}
                >
                  {policyTermToShow} Years
                </QuoteDataTextMedium>
              </>
            ) : (
              <>
                <QuoteDataTextMedium
                  css={`
                    margin: 0px 0px 0px 0px;
                  `}
                >
                  {incomeStartAtValue}
                </QuoteDataTextMedium>
              </>
            )}
          </QuoteDataSub>
          <GetDetailButton onClick={handleGetDetails}>
            Get Details
          </GetDetailButton>
        </div>

        {/* <CardBottom>
          <DeathBenefit>Death Benefit {deathBenefit}</DeathBenefit>
        </CardBottom> */}
      </QuoteCardContainer>
      <GetDetails
        show={showDetails}
        setShow={setShowDetails}
        company_alias={quoteData?.company_alias}
        quoteData={quoteData}
      />
    </>
  );
}

const QuoteCardContainer = styled.div`
  display: none;
  ${mq.md} {
    display: block;
    width: 96%;
    height: fit-content;
    margin: auto;
    border: solid 1px;
    position: relative;
    border-radius: 10px;
    box-sizing: border-box;
    padding: 5px 5px 0px 5px;
    background-color: #ffffff;
    margin-top: 10px;
  }
`;

const QuoteData = styled.div`
  display: flex;
  width: 100%;
  justify-content: 67px;
`;
// quote icon and head -- main data
const QuoteDataMain = styled.div`
  width: ${props => (props.hasMorePlans ? "70%" : "100%")};
  display: flex;
  align-items: center;
  flex-direction: row;
  min-height: 50px;
  justify-content: flex-start;
`;

const QuoteDataIcon = styled.img`
  height: 30px;
  margin-left: 10px;
`;
const GetDetailButton = styled.button`
  outline: none;
  border: none;
  margin: 0px 5px 0px 10px;
  background-color: var(--primary-color);
  text-align: center;
  padding: 3px;
  color: white;
  font-family: Inter;
  font-weight: 600;
  font-size: 10px;
  width: 19%;
  border-radius: 5px;
  ${mq.md} {
    font-size: 13px;
  }
`;

// quote sub data-- you invest , return etc
const QuoteDataSub = styled.div`
  width: 27%;
  text-align: center;
  border-right: ${props =>
    !props.last ? "1px solid var(--border-light);" : ""};
`;
const QuoteDataTextMedium = styled.p`
  font-size: 10px;
  font-family: Inter;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.7);
  height: fit-content;
  ${mq.xs} {
    font-size: 9.8px;
  }
`;
const QuoteDataNumericValues = styled.p`
  font-size: 12px;
  font-family: Inter;
  font-weight: 900;
  margin: 0px 0px 0px 0px;
  height: fit-content;
  ${mq.xs} {
    font-size: 11px;
  }
`;

// bookmark section

const QuoteCardMobileBookmark = styled.div`
  height: fit-content;
  width: fit-content;
  position: absolute;
  top: 7px;
  right: 10px;
`;

// death-benefit and get details

// const CardBottom = styled.div`
//   width: 97%;
//   margin: auto;
//   display: flex;
//   justify-content: space-between;
//   height: 25px;
//   margin-top: 10px;
//   margin-bottom: 8px;
//   ${mq.xs} {
//     margin-top: 7px;
//     margin-bottom: 5px;
//   }
// `;

// const DeathBenefit = styled.div`
//   border-radius: 100px;
//   padding: 4px 15px;
//   display: flex;
//   align-items: center;
//   background-color: rgba(44, 212, 74, 0.2);
//   font-size: 10px;
//   font-family: Inter;
//   font-weight: 500;
//   ${mq.xs} {
//     padding: 2px 10px;
//     font-size: 9.8px;
//   }
// `;

//addon section
const ShowMorePlanDiv = styled.div``;

export default QuotesCardMobile;
