import React, { useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components/macro";
import { amount } from "../../utils";
import { usePlanCard, usePlanDetails } from "./RiderPlanCard/planCard.util";
import PlanCardPopup from "./RiderPlanCard/PlanCardPopup";
import { ContinueToBuy } from "./RiderPlanCard/RiderNewPlanCard";

function RiderPageFooterMobile() {
  const { totalPremium } = usePlanCard();
  const { paymentOption, payFrequency } = usePlanDetails();

  return (
    <div
      css={`
        background-color: #fff;
        box-shadow: rgba(111, 100, 111, 0.2) 29px 7px 29px 0px;
        bottom: 0;
        position: fixed;
        width: 100%;
        padding: 5px 10px;
        display: flex;
        justify-content: space-between;
        padding: 15px 10px;
        align-items: center;
        z-index: 9;
      `}
    >
      {" "}
      <div
        css={`
          display: flex;
          flex-direction: column;
          justify-content: center;
        `}
      >
        <span
          css={`
            color: #828285;
            font-size: 12px;
          `}
        >
          Total Premium Payable
        </span>
        <span
          css={`
            font-weight: 600;
            font-size: 12.5px;
          `}
        >
          {amount(totalPremium)}
          {" / "}
          {paymentOption === "Single Pay" ? "Pay Once" : payFrequency}
        </span>
      </div>
      <div>
        <DetailsButton />
      </div>
      <div
        css={`
          // font-size: 12px;
        `}
      >
        <ContinueToBuy />
      </div>
    </div>
  );
}

export default RiderPageFooterMobile;

const DetailsButton = () => {
  const [showModal, setShowModal] = useState(false);

  const handleClose = () => setShowModal(false);
  // const handleShow = () => showModal(true);
  return (
    <>
      <button
        css={`
          background: var(--tertiary-shade);
          font-family: Inter-SemiBold;
          font-size: 11px;
          font-weight: 900;
          border-radius: 5px;
          border: none;
          padding: 15px;
          margin-top: 10px;
        `}
        onClick={() => setShowModal(true)}
      >
        View Details
      </button>
      <PlanCardPopup show={showModal} handleClose={handleClose} />
    </>
  );
};
