import React, { useEffect, useState } from "react";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { toast } from "react-toastify";
import TextInput from "../../TextInput/TextInput";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "../../../Landing.style";

import { saveOtpData, sendOtpData } from "../../../input.slice";
import { allowOnlyNumbers } from "../../../../InputUtils/onInput";


function OTPFormGeneral({ mobileNumber, email, setOtp, otp }) {

    const { otpData, otpResponse } = useSelector(state => state.inputForm);
    const [inputOTP, setInputOTP] = React.useState("");
    const [verified, setVerified] = React.useState(false);
    const [disable, setDisable] = useState(true)
    const dispatch = useDispatch()
    console.log("otpResponse", otpResponse);
    const { investmentinputpage, investmentsettings } = useSelector(
        state => state.frontendBoot.frontendData,
    );
    useEffect(() => {
        if (inputOTP.length >= 6) {
            setDisable(false)
        } else {
            setDisable(true)
        }
    }, [inputOTP.length])
    useEffect(() => {
        if (investmentsettings?.otp_journey == "Yes" && otp == true) {
            setOtp(true)
        }
    }, [otpData?.message, otpData?.uuid])
    useEffect(() => {
        if (otpResponse?.status == true && otpResponse.message == "Verified") {
            setOtp(false)
        }
    }, [otpResponse?.status])
    return (
        <div>
            <div>
                <Dialog
                    open={otp}
                    fullWidth
                    onClose={verified}
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogTitle>{"OTP Verification"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText
                            id="alert-dialog-slide-description"
                            style={{ marginTop: "15px" }}
                        >
                            <TextInput
                                label="Email"
                                value={email}
                                inputProps={{
                                    readOnly: true,
                                }}
                            />
                            <TextInput
                                className="mt-4"
                                label="Enter You Mobile Number"
                                value={mobileNumber}
                                inputProps={{
                                    readOnly: true,
                                }}
                            />
                            <TextInput
                                className="mt-4"
                                label="Enter OTP"

                                value={inputOTP}
                                onChange={e => setInputOTP(e.target.value)}
                                onInput={allowOnlyNumbers}
                            />

                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>

                        <>
                            <Button
                                style={{
                                    display: "flex",
                                    flex: "1 1",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    fontWeight: "900",
                                    height: "50px",
                                    backgroundColor: `${disable == true ? "#969799" : "var(--primary-color)"}`
                                }}

                                disabled={disable}
                                onClick={
                                    (e) => {
                                        e.preventDefault()
                                        if (inputOTP.length >= 6 && disable == false) {
                                            dispatch(sendOtpData(
                                                { otp: inputOTP }
                                            ))
                                        }

                                    }
                                }
                            >
                                Verify

                            </Button>
                            <Button
                                style={{
                                    display: "flex",
                                    flex: "1 1",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    fontWeight: "900",
                                    height: "50px",
                                }}
                                onClick={
                                    (e) => {
                                        e.preventDefault()


                                        dispatch(
                                            saveOtpData({
                                                mobile: mobileNumber,
                                                email: email,
                                                section: "term",
                                            })
                                        )
                                    }


                                }
                            >
                                Resend OTP

                            </Button>

                        </>


                    </DialogActions>
                </Dialog>
            </div>
        </div>
    );
}

const AppButton = ({ children, ...props }) => {
    return (
        <button
            {...props}
            css={`
        border: none;
        padding: 4px 8px;
        cursor: pointer;
        font-size: 14px;
        &:hover {
        }
      `}
        >
            {children}
        </button>
    );
};

export default OTPFormGeneral;
