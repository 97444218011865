import React, { useEffect } from "react";
import { Modal } from "react-bootstrap";
import { FaCheck } from "react-icons/fa";
import { useSelector } from "react-redux";
import styled from "styled-components/macro";
import { AddCompareLoader } from "../../../components/Loaders/CardSkeletonLoader/ComparePageLoader/ComparePageLoader";
import { amount } from "../../../utils";
import {
  selectCompanies,
  selectICLogo,
} from "../../frontendBoot/frontendBoot.slice";

import {
  useGetCompareProductsQuery,
  useGetDifferentQuoteQuery,
  useGetQuoteQuery,
  usePutCompareProductsMutation,
} from "../../rider/services/rider";

import * as mq from "../../../styles/mediaQueries";
// import { usePlanDetails } from '../../rider/component/PlanCard/planCard.util';
// import { selectQuotes } from "../../product-page/QuotesPage/Quotes.slice";
import useMediaQuery from "../../../custom-hooks/useMediaQuery";

import { CloseButton } from "../..";
import { toast } from "react-toastify";
import { selectDifferentQuotes } from "../../QuotesPage/Quotes.slice";
import _ from "lodash";

const AddPlanPopupContext = React.createContext();
AddPlanPopupContext.displayName = "AddPlanPopupContext";

function useAddPlanPopup() {
  const context = React.useContext(AddPlanPopupContext);

  if (!context)
    throw new Error(
      `useAddPlanPopup must be used inside AddPlanPopupContextProvider`,
    );

  return context;
}

function AddPlanPopup({ show = false, onClose = () => {}, ...props }) {
  const { data } = useGetCompareProductsQuery();
  const [putCompareQuotes] = usePutCompareProductsMutation();

  const [compareQuotes, setCompareQuotes] = React.useState([]);

  const matches = useMediaQuery(mq.mdForHook);

  let maxQuotesLength = matches ? 2 : 3;

  const addQuoteToCompare = product => {
    if (compareQuotes.length >= maxQuotesLength) {
      toast.warning(`Maximum ${maxQuotesLength} plans can be selected!`, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
      });
      return;
    }
    setCompareQuotes(prevQuotes => [...prevQuotes, product]);
  };

  const removeQuoteFromCompare = ({ product_id }) => {
    setCompareQuotes(prevQuotes =>
      prevQuotes.filter(product => product.product_id !== product_id),
    );
  };

  const toggleCompareQuote = ({
    company_alias,
    product_id,
    investment_insurance_id,
    isDifferent,
  }) => {
    if (compareQuotes.some(product => product.product_id === product_id)) {
      removeQuoteFromCompare({ product_id });
      return;
    }
    addQuoteToCompare({
      product_id,
      company_alias,
      investment_insurance_id,
      different_quote: isDifferent ? "Y" : "N",
    });
  };

  const updateCompareQuotes = () => {
    if (compareQuotes.length < 2) {
      toast.warning(`Please Select At Least Two Plan To Compare!`, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
      });
      return;
    }
    putCompareQuotes({ products: compareQuotes }).then(() => {
      onClose();
    });
  };

  useEffect(() => {
    if (data) {
      const { products } = data.data;
      if (products.length > 0) {
        setCompareQuotes(products);
      }
    }
  }, [data]);
  const { showDifferentQuotes } = useSelector(state => state.quotes);
  const diffQuotes = useSelector(selectDifferentQuotes);
  return (
    <AddPlanPopupContext.Provider
      value={{
        addQuoteToCompare,
        removeQuoteFromCompare,
        compareQuotes,
        updateCompareQuotes,
        toggleCompareQuote,
      }}
    >
      <Modal
        show={show}
        onHide={onClose}
        size="lg"
        centered
        css={`
          max-height: 700px;
          color: #233d63;
          ${mq.md} {
            max-height: 750px;
          }
        `}
        {...props}
      >
        <Modal.Header>
          <span
            css={`
              width: 5px;
              height: 30px;
              background: #ffcb00;
              position: absolute;
              left: 0;
              border-radius: 0 50px 50px 0;
            `}
          ></span>
          <Title>Add upto {maxQuotesLength} plans to compare</Title>
          <CloseButton
            css={`
              right: 5px !important;
              top: 5px !important;
              ${mq.md} {
                right: 20px !important;
                top: 20px !important;
              }
            `}
            onClick={onClose}
          />
        </Modal.Header>
        <Modal.Body
          css={`
            padding: auto;
            ${mq.md} {
              padding: 0 8px 0;
            }
          `}
        >
          <AddQuoteCards />
          {showDifferentQuotes && diffQuotes && (
            <div
              css={`
                text-align: center;
                font-size: 18px;
                font-weight: 800;
              `}
            >
              Plans with slightly different criteria
            </div>
          )}
          <div>
            <AddQuoteCards different={true} />
          </div>
        </Modal.Body>

        <Modal.Footer
          css={`
            padding: 10px;
            ${mq.md} {
              width: 100%;
              background-color: inherit;
              padding: 0;
            }
          `}
        >
          <StyledCompareButton onClick={updateCompareQuotes}>
            Compare
          </StyledCompareButton>
        </Modal.Footer>
      </Modal>
    </AddPlanPopupContext.Provider>
  );
}

export { AddPlanPopup };

export function AddQuoteCards({ different }) {
  let companies = useSelector(selectCompanies);
  companies = Object.keys(companies || {});
  return (
    <div
      css={`
        display: flex;
        align-item: center;
        justify-content: justified;
        flex-wrap: wrap;
        max-height: 600px;
        padding: 0 30px;
        overflow: auto;
        ${mq.md} {
          padding: 0 5px 0px 5px;
          margin-bottom: 2em;
          max-height: 400px;

          justify-content: center;
        }
      `}
    >
      {companies.map(company => (
        <AddQuoteCard company_alias={company} isDifferent={different} />
      ))}
    </div>
  );
}

function AddQuoteCard({ company_alias, isDifferent, ...props }) {
  const { newFilters } = useSelector(state => state.inputForm);

  const { isLoading, isUninitialized, isFetching, data } = useGetQuoteQuery({
    company_alias,
    newFilters,
  });
  const { data: different_data, isLoading: isDifferentQuoteLoading } =
    useGetDifferentQuoteQuery(
      { company_alias, newFilters },
      {
        skip: !isDifferent,
      },
    );

  const { toggleCompareQuote, compareQuotes } = useAddPlanPopup();

  const logoSrc = useSelector(selectICLogo(company_alias));

  const quote = isDifferent
    ? different_data
      ? different_data.data[0]
      : null
    : data
    ? data.data[0]
    : null;
  // console.log({ quote });

  if (isLoading || isFetching || isUninitialized || isDifferentQuoteLoading)
    return (
      <AddQuoteCardWrap>
        <AddCompareLoader />
      </AddQuoteCardWrap>
    );
  if (!quote) return null;
  const handleCardClick = ({ product_id, investment_insurance_id }) => {
    toggleCompareQuote({
      company_alias,
      product_id,
      isDifferent,
      investment_insurance_id,
    });
  };
  const uniqueQuotes = _.uniqBy(
    isDifferent ? different_data.data : data.data,
    item => item?.investment_insurance_id,
  );
  return uniqueQuotes.map(item => {
    const {
      total_premium,
      investment_insurance_id,
      product: { id: product_id, name },
    } = item;
    const isAddedToCompare = compareQuotes.some(
      product => product.product_id === product_id,
    );
    return (
      <AddQuoteCardWrap
        checked={isAddedToCompare}
        onClick={() => handleCardClick({ product_id, investment_insurance_id })}
        {...props}
      >
        {isAddedToCompare ? <CheckMarkIcon /> : <EmptyCircle />}
        <div
          css={`
            width: 50%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: space-around;
            ${mq.md} {
              align-items: center;
              width: 0;
              max-height: 5em;
            }
          `}
        >
          <img
            css={`
              width: 80px;
              height: 35px;
              ${mq.md} {
                width: 70px;
                height: 30px;
              }
            `}
            src={logoSrc}
            alt="company"
          />
          <div
            css={`
              font-size: 10px;
              text-align: left;
              width: 115px;
              font-family: "Inter";
              font-weight: 600;
              display: block;
              padding-top: 2px;
              ${mq.md} {
                text-align: center;
                padding-top: 4px;
              }
            `}
          >
            {name}
          </div>
        </div>
        <div
          css={`
            text-align: right;
            width: 50%;

            ${mq.md} {
              display: flex;
              text-align: center;
              flex-direction: column;
              align-items: center;
              width: 0;
            }
          `}
        >
          <div
            css={`
              font-size: 18px;
              width: 100px;
              ${mq.md} {
                color: #0182ff;
                font-size: 0.9rem;
              }
            `}
          >
            {amount(total_premium)}
          </div>
        </div>
      </AddQuoteCardWrap>
    );
  });
}
function EmptyCircle() {
  return (
    <div
      css={`
        ${mq.md} {
          height: 2em;
          width: 2em;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          border: 1px solid #a3a3a3;
          position: absolute;
          right: 0;
          top: 0;
          transform: translate(30%, -30%);
          background-color: #fff;
        }
      `}
    ></div>
  );
}

function CheckMarkIcon({ ...props }) {
  return (
    <div
      css={`
        height: 2em;
        width: 2em;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        border: 1px solid #0caeee;
        position: absolute;
        right: 0;
        top: 0;
        transform: translate(30%, -30%);
        background-color: #fff;
      `}
      {...props}
    >
      <FaCheck />
    </div>
  );
}

const AddQuoteCardWrap = styled.button`
  position: relative;
  color: inherit;
  font-family: "Inter";
  font-weight: 600;
  background: none;
  max-width: 30%;
  min-height: 8em;
  flex: 1 0 30%;
  box-shadow: rgb(0 0 0 / 20%) 0px 2px 8px 0px;
  margin: 0.6em;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 6px;
  border: 1px solid;
  border-color: ${props => (props.checked ? "#0caeee" : "transparent")};
  padding: 1em;
  &:hover {
    border-color: #0caeee;
  }
  ${mq.md} {
    flex: 50%;
    max-width: 40%;
    flex-direction: column;
    justify-content: center;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }
`;

const Title = styled.h1`
  margin: 0;
  font-size: 1.27rem;
  font-family: "Inter";
  font-weight: 600;
  ${mq.md} {
    font-size: 1rem;
    padding-left: 5px;
  }
`;

const StyledCompareButton = styled.button`
  background-color: var(--primary-color);
  font-family: "Inter";
  font-weight: 600;
  border: none;
  padding: 0.6em 1em;
  border-radius: 0.37em;
  color: #fff;
  ${mq.md} {
    width: 100%;
  }
`;
