import React, { useEffect } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import FormWithTitle from "../../FormWithTitle/FormWithTitle";
import { Radio, RadioWrapper } from "../../../Landing.style";
import male from "./../../../../../assets/images/male.png";
import female from "./../../../../../assets/images/female.png";
import cigarette from "../../../../../assets/images/cigarette.png";
import { allowOnlyNumbers } from "../../../../InputUtils/onInput";

import dontSmoke from "../../../../../assets/images/dont-smoke.png";
import "styled-components/macro";
import { InputAdornment } from "@mui/material";
import styled from "styled-components";
import { setData } from "../../../input.slice";
import { dateUtil, form1Schema } from "../../../validations";
import TextInput from "../../TextInput";

const getLocalData = () => {
  const field_1 = localStorage.getItem("Investment Basic Details");
  if (field_1) return JSON.parse(field_1);
  else
    return {
      gender: "M",
      tobacco_habit: "NS",
      annual_income: 1000000,
    };
};

const Premium = ({ setCurrent }) => {
  const dispatch = useDispatch();
  const { investmentinputpage } = useSelector(
    state => state.frontendBoot.frontendData,
  );

  const [age, setAge] = React.useState(getLocalData()?.age);

  const {
    register,
    formState: { errors },
    clearErrors,
    watch,
    handleSubmit,
    reset,
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(form1Schema({ minAge: 18, maxAge: 65 })),
    defaultValues: {},
  });

  useEffect(() => {
    reset(getLocalData());
  }, [reset]);

  function calcFunction() {
    return value => {
      var with2Decimals = value.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];
      return with2Decimals;
    };
  }

  const date = watch("dob");
  const termAnnualIncome = watch("annual_income");
  const calc = calcFunction();
  const getInWords = getInWordsFunction();

  const onSubmit = data => {
    setCurrent(prev => prev + 1);
    dispatch(
      setData({
        ...data,
        dob: data.dob.split(" / ").reverse().join("-"),
        annual_income: parseInt(data.annual_income),
      }),
    );
    localStorage.setItem(
      "Investment Basic Details",
      JSON.stringify({
        tobacco_habit: data?.tobacco_habit,
        dob: data?.dob && data.dob.split("-").reverse().join(" / "),
        gender: data?.gender,
        annual_income: parseInt(data.annual_income),
        age: age,
      }),
    );
  };

  return (
    <div>
      <FormWithTitle
        current={0}
        onSubmit={handleSubmit(onSubmit)}
        showButton
        title={
          investmentinputpage?.investmentinputpage1
            ? investmentinputpage?.investmentinputpage1
            : "<h1>Let's start with some basic details</h1>"
        }

        // title={"Let's start with some basic details"}
      >
        {/* <div className="d-flex justify-content-start  ">
          <h4
            className="fw-bolder mb-2"
            css={`
              ${mq.sm} {
                font-size: 19px;
                width: 100%;
                text-align: center;
                margin-bottom: 0px;
                color: var(--primary-color-dark);
              }
            `}
          >
            Let's Start With Some Basic Details
          </h4>
        </div> */}
        <div>
          <RadioWrapper>
            <Radio>
              <input
                type="radio"
                id="gen_m"
                value="M"
                name="gender"
                {...register("gender")}
              />
              <label htmlFor="gen_m">
                <img src={male} alt="gender" />
                <span>Male</span>
              </label>
            </Radio>
            <Radio>
              <input
                type="radio"
                id="gen_f"
                value="F"
                name="gender"
                {...register("gender")}
              />
              <label htmlFor="gen_f">
                <img src={female} alt="gender" />
                <span>Female</span>
              </label>
            </Radio>
          </RadioWrapper>
          <RadioWrapper>
            <Radio>
              <input
                type="radio"
                id="smoker"
                value="S"
                name="tobacco_habit"
                {...register("tobacco_habit")}
              />
              <label htmlFor="smoker">
                <img src={cigarette} alt="tobacco_habit" />
                <span>Smoker</span>
              </label>
            </Radio>

            <Radio>
              <input
                type="radio"
                id="non-smoker"
                value="NS"
                name="tobacco_habit"
                {...register("tobacco_habit")}
              />
              <label htmlFor="non-smoker">
                <img src={dontSmoke} alt="tobacco_habit" />
                <span>Non Smoker</span>
              </label>
            </Radio>
          </RadioWrapper>

          <BirthDate>
            <TextInput
              className=" w-100 "
              name="dob"
              label="Date of Birth"
              placeholder="DD/MM/YYYY"
              register={register}
              error={errors.dob}
              errorIcon={errors.dob ? true : false}
              helperText={errors.dob && errors.dob.message}
              clearErrors={clearErrors}
              onChange={e => {
                const age = dateUtil(e);
                setAge(age);
                register("dob").onChange(e);
                clearErrors("dob");
              }}
              value={date}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {/* {errors.dob && errors.dob.message ? (
                      <span className=" fs-5">
                        <MdError />
                      </span>
                    ) : ( */}
                    {age && `${age} years`}
                    {/* )} */}
                  </InputAdornment>
                ),
              }}
              inputProps={{
                maxLength: 14,
                inputMode: "numeric",
              }}
            />
          </BirthDate>

          {/* <AnnualIncome>
            <Select
              label="Annual Household Income"
              options={income}
              error={errors.annual_income && errors.annual_income.message}
              handleChange={val => {
                setAnnualIncome(parseInt(val));
                setValue("annual_income", val);
                trigger("annual_income");
              }}
              selectedValue={annualIncome.toString()}
            />
          </AnnualIncome> */}

          <AnnualIncome>
            <div className="w-100 mt-4">
              <TextInput
                name="annual_income"
                label="Annual Household Income"
                register={register}
                onInput={allowOnlyNumbers}
                error={errors.annual_income}
                errorIcon={errors.annual_income ? true : false}
                helperText={
                  errors.annual_income && errors.annual_income.message
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {errors.annual_income &&
                      errors.annual_income.message &&
                      !termAnnualIncome ? (
                        <span className="fs-5">{/* <MdError /> */}</span>
                      ) : (
                        <span
                          css={`
                            color: var(--primary-color);
                          `}
                        >
                          {getInWords(termAnnualIncome)}
                        </span>
                      )}
                    </InputAdornment>
                  ),
                }}
                inputProps={{
                  maxLength: 10,
                  inputMode: "numeric",
                }}
              />
            </div>
          </AnnualIncome>
        </div>
      </FormWithTitle>
    </div>
  );
  function getInWordsFunction() {
    return value => {
      if (!value) return null;
      value = String(value);
      const length = value.length;
      if (length >= 8) {
        return `${calc(value / Math.pow(10, 7))} Cr`;
      } else if (length >= 6) {
        return `${calc(value / Math.pow(10, 5))} Lac`;
      } else if (length >= 4) {
        return `${calc(value / Math.pow(10, 3))} Th`;
      } else return value;
    };
  }
};

export default Premium;

const BirthDate = styled.div`
  width: 100%;
  ${
    "" /* input:focus {
    outline: none;
  } */
  }
`;

const AnnualIncome = styled.div`
  width: 100%;
  padding: 0px;
  margin-top: 20px;
  ${
    "" /* input:focus {
    outline: none;
  }
  & .MuiFormControl-root {
    width: 100%;
  } */
  }
`;
