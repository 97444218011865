import { Box, Container, useMediaQuery } from "@mui/material";
import React from "react";
import { Table } from "react-bootstrap";
import { BiArrowBack, BiLeftArrowCircle } from "react-icons/bi";
import { useNavigate } from "react-router";
import useUrlQuery from "../../../custom-hooks/useUrlQuery";
import * as mq from "../../../styles/mediaQueries";
import NavbarMobile from "./Mobile/components/NavbarMobile";
import Navbar from "../../../components/navbar/Navbar-Component";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { useSelector } from "react-redux";
import styled from "styled-components/macro";
import parse from "html-react-parser";

export default function InvestInfo() {
  const enquiryId = useUrlQuery("enquiryId");
  const navigate = useNavigate();
  const matches = useMediaQuery(`${mq.mdForHook}`);
  const { investmentquotepage } = useSelector(
    state => state.frontendBoot?.frontendData,
  );

  return (
    <Box>
      {matches ? <NavbarMobile /> : <Navbar />}
      <div
        style={{
          fontSize: "26px",
          cursor: "pointer",
          display: "flex",
          alignItems: "center",
          gap: "8px",
          position: "absolute",
          left: 50,
          top: 80,
        }}
        onClick={() => navigate(`/quote?enquiryId=${enquiryId}`)}
      >
        <>
          <AiOutlineArrowLeft />
        </>
      </div>
      <Wrap className="container">
        {investmentquotepage?.investmentdefinitiondata ? (
          parse(investmentquotepage.investmentdefinitiondata)
        ) : (
          <div
            className="container"
            css={`
              margin-top: 20%;
              text-align: center;
              font-size: 30px;
              font-weight: 600;
            `}
          >
            No Data
          </div>
        )}
      </Wrap>
    </Box>
  );
}

const Wrap = styled.div`
  padding: 20px 40px;
  margin-bottom: 10px;
  font-family: "Inter" !important;
  h2 {
    font-size: 24px;
    margin-top: 50px;
    font-weight: 700;
    color: rgb(74, 89, 113);
  }
  p {
    width: 80%;
    font-size: 15px;
  }
  table,
  td,
  th {
    border: 2px solid rgb(190, 190, 190);
  }

  tr td:first-child {
    width: 25%;
  }

  table {
    width: 80% !important;
    border-collapse: collapse;
    margin-top: 40px;
  }
  td {
    padding: 10px;
    font-size: 14px;
  }
  th {
    padding: 10px;
    font-size: 16px;
    font-weight: 700;
  }
  li {
    font-size: 14px;
  }
`;
