import React from "react";
import "styled-components/macro";
import styled from "styled-components/macro";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import CancelCircleIcon from "../../../../../assets/svg/CancelCircleIcon";
import {
  allowOnlyNumbers,
  allowOnlyUniqueAlphabetsNoSpace,
  toCapitalize,
} from "../../../../InputUtils/onInput";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { form3, form4 } from "../../../../../pages/Landing/validations";
import { lowerCase } from "../../../../../pages/Landing/validations";
import * as mq from "../../../../../styles/mediaQueries";
import TextInput from "./TextInput";
import parse from "html-react-parser";
import { shareContactDetails } from "../../../../Landing/input.slice";
import { useDispatch } from "react-redux";
const ContactUsBtnComp = ({ show, setShow }) => {
  const { data } = useSelector(state => state.inputForm);
  const { investmentquotepage } = useSelector(
    state => state.frontendBoot.frontendData,
  );
  const handleClose = () => setShow(false);

  const {
    register,
    trigger,
    handleSubmit,
    setValue,

    formState: { errors },
  } = useForm({
    resolver: yupResolver(form4),
    mode: "onChange",
    defaultValues: {
      name: data.name,
      email: data.email,
      mobile: data.mobile,
    },
  });
  // console.log("erorrr", errors);
  const dispatch = useDispatch();
  const [mobile, setMobile] = useState(data.mobile);
  const [email, setEmail] = useState(data.email);
  const [name, setName] = useState(data.name);
  const [shared, isShared] = useState();

  const onSubmit = details => {
    dispatch(shareContactDetails(details));
    isShared(true);
    setTimeout(() => {
      isShared(false);
    }, 4000);
  };

  return (
    <div>
      <Modal
        show={show}
        onHide={handleClose}
        centered
        css={`
          .modal-dialog {
            max-width: 36%;

            ${mq.md} {
              max-width: 70%;
            }
            ${mq.sm} {
              max-width: 100%;
            }
          }
        `}
      >
        <Modal.Header>
          <CancelCircleIcon
            width="30"
            onClick={() => handleClose()}
            css={`
              position: absolute,
              right: -15px,
              top: -15px,
              ${mq.sm} {
                right: 1px;
                top: 2px;
              }
            `}
          />
          <ModalHeading proposerName={name}>
            {investmentquotepage?.contactuspopupheading
              ? parse(investmentquotepage?.contactuspopupheading)
              : parse(`<h1>Please Share Your Contact Details</h1>`)}
          </ModalHeading>
        </Modal.Header>
        <Modal.Body>
          <div
            css={`
              display: flex;
              flex-direction: column;
              margin-top: 0.5em;
            `}
          >
            <form
              onSubmit={handleSubmit(onSubmit)}
              css={`
                width: 70%;
                margin: auto;
                ${mq.sm} {
                  width: 100%;
                }
              `}
            >
              <div
                css={`
                  width: auto;
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  align-items: center;
                  ${mq.sm} {
                    width: 100%;
                  }
                `}
              >
                <TextInput
                  {...register("name")}
                  label={"Enter your Name"}
                  onChange={e => {
                    setValue("name", e.target.value);
                    trigger("name");
                    setName(e.target.value);
                  }}
                  value={name}
                  // capitalize={true}
                  capitalize="true"
                  onInput={toCapitalize && allowOnlyUniqueAlphabetsNoSpace}
                  error={errors.name && errors.name.message}
                  inputProps={{ maxLength: "50" }}
                  defaultValue={name}
                />
              </div>
              <div
                css={`
                  width: auto;
                  margin-top: 16px;
                  display: flex;
                  flex-direction: column;
                  height: 58px;
                  justify-content: center;
                  align-items: center;
                  ${mq.sm} {
                    width: 100%;
                  }
                `}
              >
                <TextInput
                  label={"Enter your Number"}
                  {...register("mobile")}
                  onChange={e => {
                    setValue("mobile", e.target.value);
                    trigger("mobile");
                    setMobile(e.target.value);
                  }}
                  error={errors.mobile && errors.mobile.message}
                  onInput={allowOnlyNumbers}
                  value={mobile}
                  inputProps={{ maxLength: "10" }}
                />
              </div>
              <div
                css={`
                  width: auto;
                  margin-top: 16px;
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  align-items: center;
                  height: 58px;
                  ${mq.sm} {
                    width: 100%;
                  }
                `}
              >
                <TextInput
                  label={"Enter your Email"}
                  {...register("email")}
                  onChange={evt => {
                    evt.target.value = evt.target.value.replace(" ", "");
                    lowerCase(evt);
                    // register("email").onChange(evt);
                    setValue("email", evt.target.value);
                    trigger("email");

                    setEmail(evt.target.value);
                  }}
                  value={email}
                  error={errors.email && errors.email.message}
                  inputProps={{
                    minLength: 2,
                    maxLength: 50,
                    inputMode: "email",
                  }}
                />
              </div>
              {/* <div>{shared && <p>Our team will contact you ASAP</p>}</div> */}
              <div
                css={`
                  width: 100%;

                  display: flex;
                  justify-content: center;
                  align-items: center;
                  margin-top: 1em;
                  font-size: 14px;
                `}
              >
                {shared && <>Our team will contact you ASAP</>}
              </div>

              <div
                css={`
                  // margin-top: 8px;
                  display: flex;
                  justify-content: center;
                `}
              >
                <Button type="submit">Get A Callback</Button>
              </div>
            </form>

            <ContactPopupWrap>
              {investmentquotepage?.contactuspopupdata ? (
                parse(investmentquotepage?.contactuspopupdata)
              ) : (
                <>
                  <p class="contact-us-or">——— OR ———</p>
                  <p class="contact-us-text">
                    If urgent? Please call us on our Toll Free Number
                  </p>
                  <p class="contact-us-number">9999999999</p>
                  <p class="contact-us-or">——— OR ———</p>
                  <p class="contact-us-email">
                    Email us at <a href="mailto: abc@txt.com">abc@txt.com</a>
                  </p>
                </>
              )}
            </ContactPopupWrap>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ContactUsBtnComp;

const ModalHeading = styled.div`
  & h1 {
    font-size: 1.15rem;
    font-family: "Inter";
    font-weight: 600;
    padding-right: 20px;
    ${mq.sm} {
      font-size: 0.8rem;
    }
    &::before {
      content: "Hi ${props =>
        props?.proposerName ? props?.proposerName?.split(" ")[0] : ""}, ";
    }
  }

  &::before {
    content: "";
    position: absolute;
    height: 23px;
    width: 7px;
    top: 1;
    left: 0;
    background: #ffcb00;
    border-radius: 0 45px 45px 0;
  }
`;

const Button = styled.button`
  padding: 12px 36px;
  border-radius: 5px;
  background-color: var(--primary-color);
  border: var(--primary-color);
  color: #fff;
  font-size: 15px;
  margin-top: 10px;
`;
const ContactPopupWrap = styled.div`
  padding: 10px;
  margin: auto;
  margin-top: 10px;
  ${mq.sm} {
    margin-top: 5px;
  }
  p {
    margin: 0px;
    text-align: center;
  }
  .contact-us-or {
    color: #737d85;
    text-align: center;
  }
  .contact-us-text {
    color: #4d5778;
    font-size: 14px;
    margin-top: 15px;
    color: #4d5778;
    text-align: center;
    ${mq.sm} {
      margin-top: 10px;
      font-size: 12px;
    }
  }
  .contact-us-number {
    text-align: center;
    font-size: 20px;
    color: #4d5778;
    margin: 0px 0px 20px 0px;

    ${mq.sm} {
      font-weight: 600;
      font-size: 15px;
      margin: 0px 0px 12px 0px;
    }
  }

  .contact-us-email {
    text-align: center;
    color: #4d5778;
    font-size: 14px;
    margin-top: 15px;

    color: #4d5778;
    ${mq.sm} {
      margin-top: 10px;
      font-size: 14px;
    }
  }
`;
// Call us on our Toll Free Number
// Share You're Life Insurance Proposal
