import React from "react";
import styled from "styled-components/macro";

const OverlayCalSecond = () => {
  return (
    <div
      css={`
        display: flex;
        gap: 2rem;
        justify-content: center;
        align-items: center;
      `}
    >
      <div>
        <TopShade>Guranteed Saving Plan</TopShade>
        <Values
          css={`
            margin-top: 49px;
          `}
        >
          5.85%
        </Values>
        <VerticalLine>
          <GreenBox>
            <Text>Tax Free</Text>
          </GreenBox>
        </VerticalLine>
        <Values
          css={`
            margin-top: 80px;
          `}
        >
          5.85%
        </Values>
        <Values
          css={`
            margin-top: 105px;
          `}
        >
          12 Lakh
        </Values>
      </div>
      <div>
        <B>Your Investment Over 10 Years</B>
        <VerticalLine>
          <div
            css={`
              font-size: 16px;
              font-weight: 600;
              text-align: center;
              color: #0056fe;
            `}
          >
            12 Lakhs
          </div>
        </VerticalLine>
        <Values
          css={`
            margin-top: 45px;
          `}
        >
          Returns You get
        </Values>
        <VerticalLine>
          <Line />
        </VerticalLine>
        <Values>Returns After Tax</Values>
        <VerticalLine>
          <Line />
        </VerticalLine>
        <Values>In Case Of Death Your Family Gets</Values>
      </div>
      <div>
        <TopShade>Fixed Deposits</TopShade>
        <Values
          css={`
            margin-top: 49px;
          `}
        >
          5.4%
        </Values>
        <VerticalLine>
          <OrangeBox>
            <Text>Taxable</Text>
          </OrangeBox>
        </VerticalLine>
        <VerticalLine>
          <YellowButton>
            <Text1>Tax Slab: 30%</Text1>
          </YellowButton>
        </VerticalLine>

        <Values>4.96%</Values>
        <Values
          css={`
            margin-top: 90px;
          `}
        >
          Zero
        </Values>
      </div>
    </div>
  );
};

export default OverlayCalSecond;

const TopShade = styled.div`
  gap: 10px;
  padding: 10px;
  ${"" /* width: 300px; */}
  text-align: center;
  border-radius: 10px;
  background-color: rgba(146, 180, 246, 0.3);
  color: #000000;
  font-size: 18px;
  font-family: Inter;
  font-weight: 600;
`;

const B = styled.div`
  font-size: 16px;
  ${"" /* gap: 10px; */}
  padding-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Inter;
  font-weight: 600;
`;

const Values = styled.div`
  color: #000000;
  font-size: 16px;
  font-family: Inter;
  text-align: center;
  font-weight: 600;
`;
const VerticalLine = styled.div`
  display: flex;
  justify-content: center;
`;
const Line = styled.div`
  width: 2px;
  height: 60px;
  ${"" /* height: 80px; */}
  background-color: #000;
  margin-top: 16px;
  margin-bottom: 10px;
`;
const GreenBox = styled.div`
  ${"" /* width: 85px; */}
  ${"" /* height: 30px; */}
  padding: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  background-color: rgba(60, 236, 125, 0.4);
`;
const OrangeBox = styled.div`
  ${"" /* width: 85px; */}
  ${"" /* height: 30px; */}
  padding: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  background-color: rgba(255, 41, 41, 0.24);
`;
const YellowButton = styled.div`
  ${
    "" /* width: 204px;
  height: 39px; */
  }
  gap: 5px;
  padding: 5px;
  border-radius: 5px;
  border: solid 1px #f9f3d9;
  background-color: #f9f3d9;
  margin-top: 55px;
`;

const Text = styled.p`
  font-size: 12px;
  text-align: center;
  color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0px;
  font-family: Inter;
  font-weight: 500;
`;
const Text1 = styled.p`
  font-size: 16px;
  text-align: center;
  color: #000;
  margin-bottom: 0px;
  font-family: Inter;
  font-weight: 500;
`;
