import React from "react";
import styled from "styled-components/macro";
import { AiOutlineCloudDownload } from "react-icons/ai";
import useGetInvestmentData from "../../../../custom-hooks/useGetInvestmentaData";
import { useGetBiQuery } from "../../../rider/services/rider";
import lumpsumImg from "./../../../../assets/svg/lumpsum.svg";
import incomeImg from "./../../../../assets/svg/income.svg";
import stackImg from "./../../../../assets/svg/stack.svg";
import moneyTransfer from "./../../../../assets/images/moneyTransfer.png";
import bundleImg from "./../../../../assets/svg/bundle.svg";
import { GrAdd } from "react-icons/gr";
import ButtonLoader from "../../../../components/Loaders/ButtonLoader/ButtonLoader";

import { useSelector } from "react-redux";
import { getInWords } from "../../../../utils/helper";

import * as mq from "../../../../styles/mediaQueries";

import ViewDetailsPopupMobile from "./ViewDetailsPopupMobile";
import { Box, Paper, Stack, Typography } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles(theme => ({
  box: {
    height: "200px",
    "@media (min-width: 1440px)": {
      height: "212px",
    },
  },
}));
function BenefitTimeline({ quoteData }) {
  const [showModal, setShowModal] = React.useState(false);
  const { documentData } = useSelector(state => state.quotes);

  const documents = documentData;

  const {
    investedAmountInWords,
    isLumpsum,
    isBoth,
    isIncome,
    frequency,
    totalInvestment,
    isSinglePay,
  } = useGetInvestmentData({ quoteData });

  const earlyIncome = quoteData?.early_income;
  const earlyIncomeStart = quoteData?.early_income_start_year;
  const earlyIncomeEnds = quoteData?.early_income_end_year;
  const { enquiryLoading } = useSelector(state => state.inputForm);
  const { company_alias } = quoteData ? quoteData : {};
  // const { data: cartData, isLoading: isCartLoading } = useGetCartQuery();
  const filters = useSelector(state => state.filters);
  const { data: biData, isLoading: isBiLoading } = useGetBiQuery(
    {
      company_alias,
    },
    { skip: enquiryLoading, refetchOnMountOrArgChange: true },
  );
  const d = new Date();
  const handleClose = () => setShowModal(false);

  // console.log({ quoteData, filters });
  return (
    <div
      css={`
        height: 100%;
        width: 100%;
        poistion: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        ${mq.md} {
          min-height: 100%;
          width: 100%;
          flex-direction: column;
          justify-content: space-between;
          padding-top: 72px;
        }
      `}
    >
      <TimeLine>
        <TimelineCard
          title={
            isSinglePay
              ? "You Invest"
              : `You Invest for ${filters.investFor} years`
          }
          border
          isInvest={!isSinglePay}
        >
          {isSinglePay ? (
            <Box display={"flex"} justifyContent="center" py={2}>
              <InvestColoumn
                data={[
                  `${totalInvestment}`,
                  bundleImg,
                  `In : ${d.getFullYear()}`,
                ]}
              />
            </Box>
          ) : (
            <Box
              display={"flex"}
              gap="20px"
              justifyContent="center"
              flex={"1 1"}
              py={2}
              sx={{
                borderBottomLeftRadius: "14px",
                borderBottomRightRadius: "14px",
                height: "100%",
              }}
            >
              <InvestColoumn
                isInvest
                data={[
                  `${getInWords(quoteData?.investment_amount)}  ${frequency}`,
                  stackImg,
                  `From : ${d.getFullYear()}`,
                ]}
                border
              />
              <InvestColoumn
                isInvest
                data={[
                  `${totalInvestment} In ${filters.investFor} years`,
                  bundleImg,
                  `Till : ${d.getFullYear() + parseInt(filters.investFor)}`,
                ]}
              />
            </Box>
          )}
        </TimelineCard>

        <TimelineCard title="You Receive">
          <Box
            display={"flex"}
            justifyContent="center"
            height="100%"
            py={2}
            gap={"20px"}
          >
            {isLumpsum ? (
              <InvestColoumn
                data={[
                  `${getInWords(quoteData?.maturity_benefit)} as Lumpsum`,
                  lumpsumImg,
                  `At ${quoteData?.policy_term} years`,
                ]}
              />
            ) : isBoth ? (
              <>
                <InvestColoumn
                  data={[
                    `${getInWords(
                      quoteData?.income_benfit,
                    )} Income Yearly for ${quoteData.income_for} years`,
                    incomeImg,
                    `Starting at ${quoteData?.income_start_at} years`,
                  ]}
                  isMultiple
                />
                <InvestColoumn
                  data={[
                    `${getInWords(
                      quoteData?.additional_lumpsum ||
                        quoteData?.additional_rop,
                    )} Lumpsum at Policy Maturity`,
                    lumpsumImg,
                    quoteData?.additional_rop ? "Return of Premium" : "Lumpsum",
                  ]}
                />
              </>
            ) : isIncome ? (
              <>
                {earlyIncome > 0 && (
                  <>
                    <InvestColoumn
                      data={[
                        `₹ ${getInWords(earlyIncome)} as Early Income From `,
                        moneyTransfer,

                        ` ${earlyIncomeStart} Year to ${earlyIncomeEnds} Year `,
                      ]}
                      border
                    />
                  </>
                )}
                {earlyIncome > 0 && (
                  <>
                    <Box position="absolute" marginTop="86px">
                      <GrAdd fontSize="20px" />
                    </Box>
                  </>
                )}
                <InvestColoumn
                  data={[
                    `₹ ${getInWords(
                      quoteData?.income_benfit,
                    )} Yearly Income for ${filters.incomeFor} years`,
                    incomeImg,
                    `Starting at ${quoteData?.income_start_at}th year`,
                  ]}
                  border
                />
              </>
            ) : (
              // <InvestColoumn
              //   data={[
              //     `₹ ${getInWords(
              //       quoteData?.income_benfit,
              //     )} Yearly Income for ${filters.incomeFor} years`,
              //     incomeImg,
              //     `Starting at ${quoteData?.income_start_at}th year`,
              //   ]}
              //   border
              // />
              <></>
            )}
          </Box>
        </TimelineCard>
      </TimeLine>

      <div
        css={`
          margin-top: 20px;
          ${mq.md} {
            padding-top: 70px;
          }
          @media screen and (min-width: 820px) and (max-width: 884px) {
            padding-top: 212px;
          }
        `}
      >
        <div className="w-100 d-flex justify-content-center align-items-center ">
          <DownLoadSection>
            <DownText>
              <div className="downTop">Downloads</div>
              <div className="downBottom">
                To find out more about the products, kindly refer Brochure.
                Please refer to the Benefit Illustration for year wise Premium
                Amount / Maturity Benefit / Income Benefit / Booster
                / Death Benefit.
              </div>
            </DownText>
            <div
              css={`
                display: flex;
                gap: 2%;
                width: 55%;
                align-items: center;
                ${mq.lg} {
                  width: 42%;
                }
                ${mq.md} {
                  width: 100%;
                  margin-top: 1rem;
                }
              `}
            >
              <DownButton
                href={documents?.brochure_url}
                target={"_blank"}
                disabled={!documents?.brochure_url}
              >
                Brochure
                <AiOutlineCloudDownload />
              </DownButton>
              <DownButton
                disabled={isBiLoading}
                href={biData?.data[0]?.bi_url}
                download=""
                target="_blank"
              >
                {isBiLoading ? (
                  <ButtonLoader colored={true} />
                ) : (
                  <>
                    Benefit Illustration
                    <AiOutlineCloudDownload />
                  </>
                )}
              </DownButton>
            </div>
          </DownLoadSection>
        </div>

        <div
          className="w-100 d-flex justify-content-center align-items-center  "
          css={`
            ${mq.md} {
              padding: 20px 0px 12px 0px;
            }
          `}
        >
          <DetailsSection>
            <FirstPart>
              <Label>Total Investment Amount</Label>
              {/* <Value>Rs 40,135/Year</Value> */}
              <p
                className="dataSub2"
                css={`
                  font-size: 16px;
                  font-family: "Inter";
                  font-weight: 600;
                  margin-bottom: 0px;
                  ${mq.lg} {
                    font-size: 11px;
                  }
                `}
              >
                {investedAmountInWords} {!isSinglePay ? frequency : ""}
              </p>
            </FirstPart>
            <SecondPart>
              <ViewDetails onClick={() => setShowModal(true)}>
                View Details
              </ViewDetails>

              <ViewDetailsPopupMobile
                download={documents?.brochure_url}
                show={showModal}
                handleClose={handleClose}
                quoteData={quoteData}
              />
            </SecondPart>
          </DetailsSection>
        </div>
      </div>
    </div>
  );
}
const InvestColoumn = ({ isInvest, data = [], border, isMultiple }) => {
  return (
    <Paper
      sx={{
        width: "180px",
        height: "200px",
        display: "flex",
        flexDirection: "column",
      }}
      elevation={3}
    >
      <Stack
        justifyContent={"center"}
        alignItems="center"
        gap="4px"
        px={2}
        sx={{
          flex: "1 1",
          position: "relative",
        }}
      >
        <Box
          height={{
            xs: "40px",
            md: "46px",
          }}
        >
          <img src={data[1]} alt="" height={"100%"} />
        </Box>
        <Typography
          textAlign={"center"}
          sx={{
            fontWeight: "600",
            fontSize: {
              xs: "12px",
              md: "14px",
            },
            "@media screen and (min-width:1440px)": {
              fontSize: "16px",
            },
          }}
        >
          {data[0]}
        </Typography>

        <Typography
          sx={{
            color: "#bbb",
          }}
          fontSize={{
            xs: "11px",
            md: "12px",
            "@media screen and (min-width:1440px)": {
              fontSize: "14px",
            },
          }}
        >
          {data[2]}
        </Typography>
        <Typography>{data[3]}</Typography>
        {isMultiple && (
          <>
            <Box position="absolute" right="-19px">
              <GrAdd fontSize="20px" />
            </Box>
          </>
        )}
      </Stack>
    </Paper>
  );
};
const TimelineCard = ({ title, isInvest, children, border }) => {
  const classes = useStyles();
  return (
    <Box
      css={`
        width: 35%;

        ${mq.md} {
          width: 72% !important;
          margin-top: 1em !important;
        }
      `}
      sx={{
        position: "relative",
        ...(border && {
          "&:after": {
            content: "''",
            height: "calc(100% + 20px)",
            bgcolor: "#ccc",
            width: "1px",
            display: "inline-block",
            position: "absolute",
            top: " 0px",
            right: "-8px",
          },
        }),
      }}
    >
      <Box
        sx={{
          textAlign: "center",
          fontWeight: 700,
          fontSize: "18px",
          py: "2px",
          position: "relative",
          mb: 2,
          "&:after": {
            position: "absolute",
            content: "''",
            height: "6px",
            width: "40px",
            bgcolor: "#46c603",
            borderRadius: "8px",
            bottom: -2,
            left: "50%",
            transform: "translateX(-50%)",
          },
          "@media screen and (min-width:1440px)": {
            fontSize: "20px",
            paddingTop: "10px",
            paddingBottom: "10px",
            "&:after": {
              bottom: 2,
            },
          },
        }}
      >
        {title}
      </Box>
      <Box display="flex" className={classes.box} justifyContent="center">
        {children}
      </Box>
    </Box>
  );
};

const TimeLine = styled.div`
  margin: 0px auto;
  width: 100%;
  border-radius: 10px;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  ${mq.md} {
    width: 100%;
    display: flex;
    margin-bottom: 0px;
    flex-direction: column;
    padding: 0px;
    height: 380px;
  }
`;

const DownLoadSection = styled.div`
  width: 85%;
  display: flex;
  justify-content: space-between;
  gap: 10px;
  padding: 13px 10px;
  box-shadow: rgb(0 0 0 / 10%) 0px 4px 4px 0px;
  border-radius: 10px;
  margin-top: 10px;
  ${mq.lg} {
    width: 90%;
    padding: 15px 10px;
  }
  ${mq.md} {
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  ${mq.xs} {
    padding: 10px;
  }
  @media screen and (min-width: 1440px) {
    box-shadow: rgb(0 0 0 / 10%) 0px 4px 26px 0px;
    height: 92px;
    margin-top: 10px;
    padding-bottom: 10px;
  }
`;

const DownText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3px;
  .downTop {
    font-size: 16px;
    font-family: "Inter";
    font-weight: 600;
    ${mq.lg} {
      font-size: 14px;
    }
    ${mq.xs} {
      font-size: 14px;
    }
    @media screen and (min-width: 1440px) {
      font-size: 22px;
    }
  }
  .downBottom {
    font-size: 12px;
    font-family: "Inter";
    ${mq.lg} {
      font-size: 11px;
    }
    ${mq.xs} {
      font-size: 10px;
    }
  }
`;

const DownButton = styled.a`
  text-decoration: none;
  height: 55px;
  width: 49%;
  color: var(--secondary-text);
  border: 1px solid rgb(0, 86, 254, 0.1);
  background-color: var(--primary-color-light);
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
  &:hover {
    border: 1px solid rgb(0, 86, 254, 0.9);
    color: var(--secondary-text);
  }

  ${mq.lg} {
    height: 44px;
    font-size: 12.5px;
    gap: 3px;
  }

  ${mq.md} {
    height: 40px;
    flex: 1;
    margin: 5px;
    font-size: 12px;
  }
  ${mq.xs} {
    height: 30px;
    font-size: 11px;
  }
`;

const DetailsSection = styled.div`
  display: none;
  ${mq.md} {
    display: flex;
    width: 90%;
    margin-top: 1rem;
  }
`;

const FirstPart = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;
const SecondPart = styled.div`
  display: flex;
  flex: 1;
`;
const Label = styled.div``;
// const Value = styled.div`
//   font-size: 16px;
//   font-weight: 600;
//   ${mq.xs} {
//     font-size: 14px;
//   }
// `;
const ViewDetails = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s ease;
  color: var(--primary-color);
  border: 1px solid var(--primary-color);
  width: 100%;
`;

export default BenefitTimeline;
