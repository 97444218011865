import React, { useState } from "react";
import QuoteCard from "../QuoteCard";
import { QuoteCardWrapper } from "./Quotes.style";
import { useGetQuoteQuery } from "../../rider/services/rider";
import { useSelector } from "react-redux";
import { selectCompanies } from "../../frontendBoot/frontendBoot.slice";
import { selectQuotes } from "../Quotes.slice";
import QuotesCardMobile from "./Mobile/QuotesCardMobile";
import { useLocation } from "react-router";
import CardSkeletonLoader from "../../../components/Loaders/CardSkeletonLoader/CardSkeletonLoader";
import GetDetails from "./Details/GetDetails";
import { Collapse } from "@mui/material";
function Quotes(props) {
  return (
    <>
      <RenderQuotes {...props} />
      <FetchQuotes />
    </>
  );
}

function RenderQuotes(props) {
  const quotes = useSelector(selectQuotes);
  let companies = Object.keys(quotes);
  const { sortedQutoes } = useSortQuotes(
    companies.map(company => quotes[company]),
  );

  const sortedAndFilteredQuotes = sortedQutoes.filter(
    quote =>
      quote &&
      companies.includes(quote[0].company_alias) &&
      Boolean(quotes[quote[0].company_alias]),
  );
  // Group by product ID and Policy Term display logic:

  return sortedAndFilteredQuotes.map((quote, index) => {
    const finalQuotesSet = new Set();
    const finalQuotes = quote.reduce((acc, curr) => {
      const key = curr.product.id;
      finalQuotesSet.add(key);
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(curr);
      return acc;
    }, {});

    const keyArr = Object.keys(finalQuotes);
    const firstQuote = finalQuotes[keyArr.shift()][0];

    return (
      <ICQuotes
        key={index}
        quote={firstQuote}
        quotes={finalQuotes}
        keyArr={keyArr}
        quotesSet={finalQuotesSet}
        {...props}
      />
    );
  });
}
function ICQuotes({
  quote: {
    company_alias,
    product: { id },
  },
  quotes,
  keyArr,
  quotesSet,
  ...props
}) {
  const { newFilters } = useSelector(state => state.inputForm);
  const { data, isLoading: isQuoteLoading } = useGetQuoteQuery({
    company_alias,
    newFilters,
  });
  const showMorePlans = useSelector(state => state.quotes.showMoreQuotes);
  const [showMorePlan, setShowMorePlan] = useState(
    showMorePlans?.includes(company_alias) ? true : false,
  );
  return (
    <>
      {Array.from(quotesSet).map((quoteKey, index) => {
        const quoteArr = quotes[quoteKey];
        return (
          <Collapse in={(showMorePlan && index > 0) || index === 0} key={index}>
            <RenderQuote
              quoteArr={quoteArr}
              data={data?.data}
              s
              showMorePlan={showMorePlan}
              setShowMorePlan={setShowMorePlan}
              isQuoteLoading={isQuoteLoading}
              keyArr={keyArr}
              key={quoteKey + index}
              hasMorePlans={index === 0 && keyArr.length > 0}
              {...props}
            />
          </Collapse>
        );
      })}
    </>
  );
}
function RenderQuote({
  quoteArr,
  data,
  showMorePlan,
  setShowMorePlan,
  isQuoteLoading,
  keyArr,
  hasMorePlans,
  ...props
}) {
  const { ShortListedPlan } = useSelector(state => state?.quotes);
  const { showDetails } = useSelector(state => state?.quotes);
  const location = useLocation();
  const [policyTermToShow, setPolicyTermToShow] = useState(
    quoteArr[0]?.policy_term,
  );

  const quote = data?.find(
    quote =>
      quote?.product?.id === quoteArr[0]?.product?.id &&
      policyTermToShow === quote?.policy_term,
  );
  const [selectedProductId, setSelectProductId] = useState();

  if (
    location.pathname === "/shortlisted-plans" &&
    !ShortListedPlan?.some(el => el === quote?.product?.id)
  ) {
    return <></>;
  }
  if (!quote) return <></>;

  return (
    <>
      <QuoteCardWrapper {...props}>
        <QuoteCard
          showAddOn={props.showAddOn}
          setShowAddOn={props.setShowAddOn}
          quoteData={quote}
          onBuyNowClick={() => {
            setSelectProductId(quote?.product?.id);
          }}
          isQuoteLoading={isQuoteLoading}
          hasMorePlans={hasMorePlans}
          setShowMorePlan={setShowMorePlan}
          showMorePlan={showMorePlan}
          morePlanCount={keyArr?.length}
          policyTermToShow={policyTermToShow}
          setPolicyTermToShow={setPolicyTermToShow}
        />
        <GetDetails
          show={quote?.product?.id === selectedProductId && showDetails}
          company_alias={quote?.company_alias}
          quoteData={quote}
          setShow={() => setSelectProductId(false)}
        />
      </QuoteCardWrapper>
      <QuotesCardMobile
        showAddOn={props.showAddOn}
        setShowAddOn={props.setShowAddOn}
        quoteData={quote}
        onBuyNowClick={() => {
          setSelectProductId(quote?.product?.id);
        }}
        hasMorePlans={hasMorePlans}
        setShowMorePlan={setShowMorePlan}
        showMorePlan={showMorePlan}
        morePlanCount={keyArr?.length}
        policyTermToShow={policyTermToShow}
        setPolicyTermToShow={setPolicyTermToShow}
      />
    </>
  );
}
function FetchQuote({ company_alias }) {
  const { newFilters } = useSelector(state => state.inputForm);
  const { isLoading, isFetching } = useGetQuoteQuery({
    company_alias,
    newFilters,
  });
  if (isLoading || isFetching) return <CardSkeletonLoader />;
  return null;
}

function FetchQuotes() {
  const companies = useSelector(selectCompanies);
  return Object.keys(companies || {}).map(company => (
    <FetchQuote key={company} company_alias={company} />
  ));
}
export default Quotes;
export function useSortQuotes(quotes) {
  let validQuotes = quotes.filter(item => item !== null);
  const sortedQutoes = [...validQuotes];
  const incomeForValue = useSelector(state => state.filters.incomeFor) || "20";
  const investForValue = useSelector(state => state.filters.investFor) || "10";

  sortedQutoes.sort((quote1, quote2) => {
    // if (!quote1 || !quote2) return -1;
    const totalAmountToBeReceived1 =
      quote1[0]?.income_benfit * incomeForValue +
      Number(quote1[0].maturity_benefit) +
      quote1[0]?.additional_lumpsum +
      quote1[0]?.additional_rop;

    const totalAmountInvested1 = quote1[0]?.premium * investForValue;
    const returns1 =
      Math.round((totalAmountToBeReceived1 / totalAmountInvested1) * 100) / 100;

    const totalAmountToBeReceived2 =
      quote2[0]?.income_benfit * incomeForValue +
      Number(quote2[0].maturity_benefit) +
      quote2[0]?.additional_lumpsum +
      quote2[0]?.additional_rop;

    const totalAmountInvested2 = quote2[0]?.premium * investForValue;
    const returns2 =
      Math.round((totalAmountToBeReceived2 / totalAmountInvested2) * 100) / 100;
    return returns2 - returns1;
  });
  return { sortedQutoes };
}
