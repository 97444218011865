import React from "react";
import "styled-components/macro";
import { yupResolver } from "@hookform/resolvers/yup";
import FormWithTitle from "../../FormWithTitle/FormWithTitle";
import * as mq from "../../../../../styles/mediaQueries";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";

import Select from "../../Select";
import {
  financialAndFamilyGoal,
  lifeStage,
  riskProfile,
} from "../../QuestionOption";
import { useDispatch, useSelector } from "react-redux";
import { setData } from "../../../input.slice";
import { form3 } from "../../../validations";

function Question({ setCurrent }) {
  const dispatch = useDispatch();
  const { investmentinputpage } = useSelector(
    state => state.frontendBoot.frontendData,
  );

  const getLocalData = () => {
    const field_2 = localStorage.getItem("Investment Question Details");
    if (field_2) return JSON.parse(field_2);
    else
      return {
        risk_profile: "",
        life_stage: "",
        financial_and_familyGoal: "",
      };
  };
  const {
    register,
    getValues,
    setValue,
    handleSubmit,
    clearErrors,
    formState: { errors },
    trigger,
  } = useForm({
    resolver: yupResolver(form3),
    mode: "onChange",
    defaultValues: getLocalData(),
  });

  const [risk, setRisk] = React.useState(getLocalData().risk_profile);
  const [life, setLife] = React.useState(getLocalData().life_stage);
  const [financial, setFinancial] = React.useState(
    getLocalData().financial_and_familyGoal,
  );

  const onSubmit = data => {
    setCurrent(prev => prev + 1);
    dispatch(setData({ suitability: { ...data } }));
    localStorage.setItem(
      "Investment Question Details",
      JSON.stringify({
        risk_profile: data.risk_profile,
        life_stage: data.life_stage,
        financial_and_familyGoal: data.financial_and_familyGoal,
      }),
    );
  };

  const [selectedValue, setSelectedValue] = React.useState({
    risk_profile: "",
    life_stage: "",
    financial_and_familyGoal: "",
  });

  return (
    <div>
      <FormWithTitle
        onSubmit={handleSubmit(onSubmit)}
        current={2}
        title={
          investmentinputpage?.investmentinputpage2
            ? investmentinputpage?.investmentinputpage2
            : "<h1>Some More Questions</h1>"
        }

        // title={"Some More Questions"}
      >
        <ProffessionForm>
          <ProffessionInputWrapper>
            <div className="w-100 ">
              <Select
                label="Risk Profile "
                options={riskProfile}
                error={errors.risk_profile && errors.risk_profile.message}
                handleChange={val => {
                  setRisk(val);
                  // setSelectedValue((prev) => ({ ...prev, risk_profile: item }));
                  setValue("risk_profile", val);
                  trigger("risk_profile");
                }}
                selectedValue={
                  risk
                  // selectedValue.risk_profile.length > 0
                  //   ? selectedValue.risk_profile
                  //   : riskProfile[0].code
                }
              />
            </div>

            <div className="w-100 mt-4">
              <Select
                label="Life Stage "
                options={lifeStage}
                error={errors.life_stage && errors.life_stage.message}
                handleChange={val => {
                  setLife(val);
                  // setSelectedValue((prev) => ({ ...prev, life_stage: item }));
                  setValue("life_stage", val);
                  trigger("life_stage");
                }}
                selectedValue={
                  life
                  // selectedValue.life_stage.length > 0
                  //   ? selectedValue.life_stage
                  //   : lifeStage[0].code
                }
              />
            </div>

            <div className="w-100 mt-4">
              <Select
                label="Financial and Family Goal "
                options={financialAndFamilyGoal}
                error={
                  errors.financial_and_familyGoal &&
                  errors.financial_and_familyGoal.message
                }
                handleChange={val => {
                  setFinancial(val);
                  // setSelectedValue((prev) => ({
                  //   ...prev,
                  //   financial_and_familyGoal: item,
                  // }));
                  setValue("financial_and_familyGoal", val);
                  trigger("financial_and_familyGoal");
                }}
                selectedValue={
                  financial
                  // selectedValue.financial_and_familyGoal.length > 0
                  //   ? selectedValue.financial_and_familyGoal
                  //   : financialAndFamilyGoal[0].code
                }
              />
            </div>
          </ProffessionInputWrapper>
          <ProffessionButton>
            <div className="w-100 d-flex justify-content-center">
              <div className="d-flex justify-content-center w-100">
                <button
                  // style={{
                  //   maxHeight: "60px",
                  //   maxWidth: "100%",
                  //   borderRadius: "5px",
                  //   border: "none",
                  //   fontSize: "1rem",
                  //   display: "flex",
                  //   alignItems: "center",
                  //   justifyContent: "center",
                  // }}

                  className="d-flex justify-content-center w-100 flex-1 align-items-center
                 m-1 border-0 py-3 btn text-black fw-bolder"
                  css={`
                    ${mq.sm} {
                      padding: 15px 0x;
                      font-size: 14px;
                    }
                  `}
                  onClick={() => {
                    setCurrent(prev => prev - 1);
                  }}
                >
                  <span> Go Back</span>
                </button>
                <button
                  type="submit"
                  // disabled={
                  //   selectedValue.riskProfile.length === 0 ||
                  //   selectedValue.lifeStage.length === 0 ||
                  //   selectedValue.financialAndFamilyGoal.length === 0
                  // }
                  className="d-flex justify-content-center w-100 flex-1 align-items-center
                 m-1 border-0  bg-primary btn text-white"
                  // style={{
                  //   fontSize: "1.1rem",
                  //   maxHeight: "60px",
                  //   maxWidth: "100%",
                  //   backgroundColor: "var(--primary-color)",
                  //   borderColor: "var(--primary-color)",
                  //   border: "none",
                  //   display: "flex",
                  //   alignItems: "center",
                  //   justifyContent: "center",
                  // }}
                  // onClick={() => {
                  //   setCurrent((prev) => prev + 1);
                  // }}
                >
                  <span>Continue</span>
                </button>
              </div>
            </div>
          </ProffessionButton>
        </ProffessionForm>
      </FormWithTitle>
    </div>
  );
}

export default Question;

const ProffessionForm = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  ${"" /* margin-bottom: 20px; */}
  ${mq.sm} {
    width: 100%;
  }
`;

const ProffessionTitle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 3.5em;

  ${mq.sm} {
    width: 100%;
  }
`;

const ProffessionInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  width: 100%;

  ${mq.sm} {
    width: 100%;
  }
`;

const ProffessionButton = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-size: 1.5em;

  ${mq.sm} {
    width: 100%;
  }
`;
export const Button = styled.button`
  width: 100%;
  background: var(--primary-color);
  padding: 20px 0;
  color: #fff;
  font-size: 20px;
  font-weight: 400;
  font-family: Inter;
  ${mq.md} {
    width: 100%;
    font-size: 14px;
  }
  ${mq.sm} {
    width: 100%;
    font-size: 14px;
  }
`;
