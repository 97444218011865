import React from "react";
import styled from "styled-components/macro";
import { Modal } from "react-bootstrap";

import PlanCard from "../PlanCard";
// import { Accordion } from "@material-ui/core";
// import CancleCirculerIcon from "../../../assets/svg-icons/CancleCircle";

const PlanCardPopup = ({
  show,
  handleClose,
  isProposalFooter,
  cartData,
  handleContinueClick,
  isRedirectionLoading,
  doneButton,
}) => {
  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        css={`
          animation-duration: 1s;
          animation-name: slidein;
          @keyframes slidein {
            from {
              margin-top: 200%;
            }
          }

          & .modal-dialog {
            position: absolute;
            bottom: 0;
            left: 0;
            margin: 0;
            z-index: 9999;
            width: 100%;
            max-width: 100%;
          }
          & .modal-content {
            border-top-left-radius: 35px;
            border-top-right-radius: 35px;
          }
        `}
      >
        {/* <Slide direction="up" in={show}> */}
        {/* <CancleCirculerIcon
          width="27"
          onClick={() => handleClose()}
          style={{
            position: "absolute",
            right: "20px",
            top: "10px",
            zIndex: "100",
            background: "none",
            boxShadow: "none",
          }}
        /> */}
        <Modal.Body
          css={`
            background: transparent;
            padding: 0;
          `}
        >
          <PlanCard
            handleContinueClick={handleContinueClick}
            cartData={cartData}
            isRedirectionLoading={isRedirectionLoading}
            doneButton={doneButton}
            handleClose={handleClose}
          />
        
        </Modal.Body>
        {/* </Slide> */}
      </Modal>
    </>
  );
};

export default PlanCardPopup;
